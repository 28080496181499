// changes up the appearance to remind you what environment you're in

@mixin env-background($color-name, $background-color) {
  background: linear-gradient(
    180deg,
    $color-name 0,
    $color-name 3px,
    $background-color 3px
  );
}

// Per-environment nav coloring for editor & guest
@mixin env-navs($color-name) {
  $bg-color: #fff;
  $active-bg-color: $gray-100;

  #top-nav {
    // disable the coloring for editor-side nav at breakpoints where it gets
    // collapsed into a menu (could do something else if we want)
    @include media-breakpoint-up(md) {
      @include env-background($color-name, $bg-color);

      .navbar-nav {
        .nav-item {
          @include env-background($color-name, $bg-color);

          &:hover:not(.active, .no-hover-state) {
            @include env-background($color-name, $active-bg-color);
          }
        }
      }
    }
  }

  .guest-primary-nav {
    @include env-background($color-name, $bg-color);

    .guest-nav-item,
    .nav-item-more {
      @include env-background($color-name, $bg-color);

      &:hover:not(.active) {
        @include env-background($color-name, $active-bg-color);
      }
    }
  }
}

.env-development {
  @include env-navs($dev-env);

  .staging-only {
    display: none;
  }
}

.env-staging {
  @include env-navs($staging-env);

  .development-only {
    display: none;
  }
}

.env-production {
  .development-only {
    display: none;
  }

  .staging-only {
    display: none;
  }
}

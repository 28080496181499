@use "sass:map";
// wraps unifilter and bulk actions
.unifilter-wrapper-bulk {
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: map.get($spacers, 3);

  .input-group {
    flex-grow: 1;
    width: auto;
    // TODO: can remove this once we've got the table width under control
    max-width: 700px;
  }
}

.unifilter-popover {
  z-index: $zindex-dropdown;
  width: 400px;
  max-width: 400px;

  .unifilter-dirty {
    &:not(.btn-transparent:hover) {
      // TODO: one-off color
      background-color: #f1f6fe;
    }

    // greater intensity option:
    //
    // background: #E1ECFF;
    // border-color: var(--#{$prefix}primary);
    // color: #4470B2;
    // font-weight: 600; // matches rounded-pill's font weight
  }
}

.btn {
  --bs-btn-icon-size: #{$btn-icon-size};
  --bs-btn-line-height: var(--bs-btn-icon-size);
  // NB: need to set svgs to currentColor for this to work with them
  --#{$prefix}btn-disabled-color: #{$gray-500};
  --#{$prefix}btn-disabled-bg: #{$gray-200};
  --#{$prefix}btn-disabled-border-color: #{$gray-200};

  // for disabled segmented controls and such so you can tell which is active
  // even if the whole thing is disabled
  &.active {
    --#{$prefix}btn-disabled-bg: #{$gray-300};
  }
}

.btn-sm {
  --bs-btn-icon-size: #{$btn-icon-size-sm};
}

.btn-lg {
  --bs-btn-icon-size: #{$btn-icon-size-lg};
}

// TODO: make font awesome one look good too
.btn.btn-icon {
  display: inline-flex;

  .material-icon {
    width: var(--bs-btn-icon-size);
    height: var(--bs-btn-icon-size);
  }

  &.btn-icon-only {
    --bs-btn-padding-x: var(--bs-btn-padding-y);
  }

  // take away half the padding from the outside and put some of it btwn the
  // button and the text
  &:not(.btn-icon-only) .material-icon:first-child {
    margin-left: calc((var(--bs-btn-padding-x) + 1px) * -0.5);
    margin-right: calc((var(--bs-btn-padding-x) + 1px) * 0.25);
  }
}

// button with an icon (probably a right arrow) at the end
.btn:not(.btn-icon-only) .material-icon:last-child {
  margin-left: calc((var(--bs-btn-padding-x) + 1px) * 0.3);
  margin-right: calc((var(--bs-btn-padding-x) + 1px) * -0.5);
}

// squishes otherwise
.btn.btn-icon-only i.fa {
  line-height: var(--bs-btn-line-height);
}

.btn.btn-primary:not(.disabled, .disabled) .spinner-border {
  --bs-spinner-track-color: currentcolor;
  --bs-spinner-cursor-color: var(--bs-secondary);
}

.btn .spinner-border {
  --bs-spinner-size: var(--bs-btn-icon-size);
}

// removed in BS5, added shim
.btn-block {
  display: block;
  width: 100%;
}

// synthetic hover state (for storybook)
.btn.hover {
  color: var(--#{$prefix}btn-hover-color);
  text-decoration: if($link-hover-decoration == underline, none, null);
  background-color: var(--#{$prefix}btn-hover-bg);
  border-color: var(--#{$prefix}btn-hover-border-color);
}

// if we want distinguish between hover and active like we do for non-outline
// buttons
//
// .btn-outline-primary {
//   --#{$prefix}btn-hover-bg: #{$blue-400};
//   --#{$prefix}btn-hover-border-color: #{$blue-400};
// }

.btn-transparent {
  @include button-variant(
    $background: rgba(255, 255, 255, 0),
    $border: rgba(255, 255, 255, 0),
    $hover-background: $gray-200,
    $hover-border: $gray-200,
    $active-background: $gray-300,
    $active-border: $gray-300,
    $disabled-color: $gray-500
  );
}

// override btn-secondary per designs
.btn-secondary {
  @include button-variant(
    $gray-200,
    $gray-200,
    $hover-background: $gray-300,
    $hover-border: $gray-300,
    $active-background: $gray-400,
    $active-border: $gray-400,
    $disabled-color: $gray-500
  );
}

.btn .badge.badge-count {
  position: absolute;
  top: 0;
  left: calc(100% - 2px);
  transform: translate(-50%, -50%);
}

// generally we don't want to show the clicky cursor on a disabled button
.btn.disabled {
  cursor: default;
}

.btn.btn-transparent {
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}btn-disabled-border-color: transparent;
}

// special treatment for input/button groups and transparent buttons within them
.input-group,
.btn-group {
  .btn .material-icon {
    transform: scale(85%);
  }

  .input-group-text .material-icon {
    transform: scale(85%);
    color: #{$gray-850}; // want secondary but looks disabled
  }

  .btn.btn-transparent {
    opacity: 1;

    --#{$prefix}btn-color: #{$gray-850}; // want secondary but looks disabled
    --#{$prefix}btn-border-color: #{$gray-300};
    --#{$prefix}btn-active-border-color: #{$gray-300};
    --#{$prefix}btn-hover-border-color: #{$gray-300};
    --#{$prefix}btn-disabled-border-color: #{$gray-300};
  }

  .form-control:not(.is-valid, .is-invalid, .rbt-input),
  .form-select:not(.is-valid, .is-invalid),
  .form-control-fake:not(.is-valid, .is-invalid),
  .input-group-text,
  .btn-transparent {
    &:not(:first-child) {
      border-left: none;
    }

    &:not(:last-child) {
      border-right: none;
    }
  }

  // form control is nested in typeaheads
  .rbt:not(:first-child, .is-valid, .is-invalid) .form-control {
    border-left: none;
  }

  .rbt:not(:last-child, .is-valid, .is-invalid) .form-control {
    border-right: none;
  }
}

/* stylelint-disable-next-line no-duplicate-selectors */
.btn {
  .btn-content-wrapper {
    transition: opacity $base-duration * 0.5 linear;
    display: flex;
    align-items: center;
  }

  .btn-indicator {
    position: absolute;
    left: 0;
    right: 0;
  }

  &.btn-busy {
    position: relative;

    .btn-content-wrapper {
      opacity: 0;
    }

    .btn-indicator {
      opacity: 1;
    }
  }
}

// for things that should semantically be buttons but we don't want to look
// like buttons
// why doesn't bootstrap provide this?
.btn-unstyled {
  display: inline;
  user-select: none;
  background-color: transparent;
  border: none;
  padding: 0 0.25rem;

  // TODO: more general solution
  &:not(.alert-link) {
    font-weight: $font-weight-normal;
    color: $body-color;
  }

  &:focus,
  &.focus {
    outline: 0;
    text-decoration: $link-hover-decoration;
    box-shadow: $btn-focus-box-shadow;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }
}

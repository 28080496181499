// removed in BS5, added shim
.form-group {
  margin-bottom: 1rem;
  // TODO: get rid of form-group and add form-label everywhere? I guess?
  label {
    @extend .form-label;
  }
}

// something happened to the cursor on form-switch in BS5
.form-switch .form-check-input {
  cursor: pointer;
}

.form-inline {
  display: flex;
  align-items: center;
}

.form-select {
  appearance: none;
}

.warning-feedback {
  width: 100%;
  margin-top: $form-feedback-margin-top;

  @include font-size($form-feedback-font-size);

  color: $orange;
}

.form-control.disabled {
  color: $input-disabled-color;
  background-color: $input-disabled-bg;
  border-color: $input-disabled-border-color;
}

.form-check-label.disabled {
  color: $text-muted;
}

.form-check-input:disabled:not(:checked, :indeterminate) {
  background-color: var(--bs-gray-200);
}

.form-control-fake {
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::after {
    content: "\00200b"; // gives it a height
  }

  &.focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;

    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }
  }
}

.form-control-fake-multi {
  overflow-x: hidden;
  height: auto;

  .form-control-fake-multi-item {
    font-size: $font-size-sm;
    font-weight: 600; // overrides rounded-pill
  }
}

fieldset:disabled .form-control-fake,
.form-control-fake.disabled {
  cursor: default;
  color: $input-disabled-color;
  background-color: $input-disabled-bg;
  border-color: $input-disabled-border-color;
}

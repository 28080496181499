// NB: removing display: inline-block fixes badge text vertical alignment
// weirdness.
.badge {
  &.text-bg-light {
    border: 1px $border-color solid;
  }

  &.bg-light,
  &.bg-warning {
    color: $body-color;
  }

  &.bg-light {
    border: 1px $border-color solid;
  }

  font-family: $font-family-base;
}

// TODO: unify styles here with typeahead badge styles and with modalpicker
// badge styles.
.removable-badge-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .removable-badge {
    @extend .badge;
    @extend .rounded-pill;
    @extend .large-pill;
    @extend .text-bg-light;

    margin: 0.25rem 0.5rem 0.25rem 0;
    cursor: pointer;

    &:hover {
      // TODO: fix hardcoded color
      background-color: #c5c5c5 !important;
    }
  }
}

.large-pill {
  font-weight: bold;
  padding: 0.3rem 0.75rem;
  font-size: 14px;
}

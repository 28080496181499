@use "sass:map";

.sticky-submit-controls {
  bottom: 0;
  left: 0;
  position: sticky;
  padding: 1rem 0;
  width: 100%;
  z-index: $zindex-sticky-submit-controls;
  // feathered
  @include gradient-y(transparent, var(--#{$prefix}body-bg), 0, 1rem);

  // alternative: border to visually separate
  //
  // background: var(--#{$prefix}body-bg);
  // border-top: 1px $border-color dashed;
}

.form-submit-controls-row {
  display: flex;
  align-items: center;
  gap: map.get($spacers, 2);
}

.computation-form {
  .form-group {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0.5rem;

    label {
      margin-bottom: 0;
      margin-right: 0.5rem;
      font-weight: bold;
    }

    .input-group {
      width: auto;
    }

    .form-text {
      flex-basis: 100%;
    }
  }
}

.field-editor {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &.field-editor-open {
    margin: 0.25rem 0;
    padding: 0.25rem;
  }
}

.mutation-field-closed {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.mutation-textarea-label label {
  margin: 0;
  // more consistent with other mutation stuff but IMO doesn't look as good
  // font-weight: $font-weight-bolder;
}

// to match .btn-icon-only

.input-group > .input-group-icon {
  padding: $btn-padding-y;
}

.input-group-sm > .input-group-icon {
  padding: $btn-padding-y-sm;
}

.input-group-lg > .input-group-icon {
  padding: $btn-padding-y-lg;
}

.list-subform {
  margin-bottom: map.get($spacers, 4);

  .card {
    margin-bottom: map.get($spacers, 3);
  }

  .card-body {
    display: flex;
  }

  .list-subform-content {
    flex-grow: 1;
  }

  .list-subform-item-controls {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    margin: calc(-1 * var(--bs-card-spacer-y))
      calc(-1 * var(--bs-card-spacer-x)) calc(-1 * var(--bs-card-spacer-y))
      calc(var(--bs-card-spacer-x));
    border-top-right-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    background: var(--bs-card-cap-bg);

    .btn {
      border-radius: 0;
    }

    .btn.list-subform-top-button {
      border-top-right-radius: $card-border-radius;
    }

    .btn.list-subform-bottom-button {
      border-bottom-right-radius: $card-border-radius;
    }
  }
}

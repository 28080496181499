@use "sass:map";
@use "sass:color";

$note-card-padding: map.get($spacers, 4);

@mixin note-card-base {
  border-radius: var(--bs-border-radius-xl);
  background-color: var(--bs-light);
  padding: $note-card-padding;
}

.note-card-create {
  @include note-card-base;

  padding: map.get($spacers, 3) $note-card-padding;
}

.note-card-wrapper {
  border-radius: var(--bs-border-radius-xl);

  &:not(:last-child) {
    margin-bottom: map.get($spacers, 3);
  }

  &:hover {
    box-shadow: $box-shadow-sm;
  }
}

.note-card {
  @include note-card-base;

  .note-entity-badge {
    padding: 4px 10px;
    border-radius: var(--bs-border-radius-xxl);
    text-transform: uppercase;
    font-size: $body-font-xs;
    white-space: nowrap;
  }

  .note-card-visible-to {
    color: $cyan-700;
  }

  .note-response {
    border-left: 2px solid $gray-400;
  }

  // All(?) top-level markdown elements have a bottom margin. We counteract it
  // with this. Unless they don't in which case this might need to get more
  // nuanced.
  .markdown > :last-child {
    margin-bottom: 0;
  }
}

@mixin assignee-background($bg-color) {
  background-color: $bg-color;

  &.loading {
    background: repeating-linear-gradient(
      135deg,
      $bg-color,
      $bg-color 40%,
      color.scale($bg-color, $lightness: 80%) 50%,
      $bg-color 60%,
      $bg-color 100%
    );
    background-size: 200% 100%;
    animation: shimmer 0.75s linear infinite;
  }
}

.note-card-assignee {
  display: flex;
  align-items: center;

  @extend .gap-2;

  padding: 10px $note-card-padding;
  border-top-left-radius: var(--bs-border-radius-xl);
  border-top-right-radius: var(--bs-border-radius-xl);

  + .note-card {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  @include assignee-background($blue-100);

  &.completed,
  &:not(.self) {
    @include assignee-background($light);
    // border-bottom: 1px solid var(--bs-border-color-translucent);
    border-bottom: 1px solid $gray-200;
  }

  &.completed {
    .note-card-user-name {
      color: var(--bs-secondary);
      text-decoration: line-through;
    }

    .user-avatar {
      opacity: 0.5;
    }
  }

  svg {
    path {
      fill: var(--bs-secondary);
    }

    &.completed path {
      fill: var(--bs-primary);
    }

    &:not(.loading) {
      &:hover path {
        fill: var(--bs-body-color);
      }

      &:hover.completed path {
        fill: $blue-600; // primary btn active bg
      }
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

// NB!: This needs to be kept in sync with public/404.html
.not-found-error-wrapper {
  text-align: center;

  h1 {
    color: var(--#{$prefix}primary);
    font-size: 10rem;
    font-weight: 700;
    text-shadow: 2px 2px #f2f2f2;
  }

  h3 {
    @extend .text-muted;

    font-weight: 500;
    margin: 1rem auto;
    max-width: 600px;
    padding: 10px;
  }

  p {
    padding-top: 10px;
  }
}

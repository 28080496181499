@use "sass:map";

.paginator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: $table-cell-padding-y;
  padding-bottom: $table-cell-padding-y;

  &.paginator-sm {
    padding-top: $table-cell-padding-y-sm;
    padding-bottom: $table-cell-padding-y-sm;
  }

  &.paginator-sticky {
    position: sticky;
    bottom: -3px; // push awkward border interaction offscreen
    margin-top: -1px; // overlap bottom border of table
    z-index: $zindex-sticky-table-header;
    background: var(--#{$prefix}body-bg);
    border-top: $table-border-width $table-border-color $border-style;
  }

  // actions on the left and right
  .page-controls {
    display: flex;
    align-items: center;
  }

  .btn.pagination-arrow {
    --bs-btn-padding-x: 0;
    --bs-btn-color: var(--bs-body-color);
  }

  .per-page-controls {
    margin-bottom: 0;

    label {
      white-space: nowrap;
      margin-right: map.get($spacers, 2);
      margin-bottom: 0;
    }
  }

  .pagination-count {
    min-width: 120px; // so it doesn't jump around when you change pages
    padding: 0 12px;
    text-align: center;
  }
}

.list-table-wrapper > .paginator {
  // TODO: padding-x should match padding x of table cells
  // which is not consistent right now b/c many have a hidden first col
  border: $table-border-width $table-border-color $border-style;
  border-top: none;
  border-bottom-left-radius: $table-border-radius;
  border-bottom-right-radius: $table-border-radius;
  padding-left: $table-cell-padding-x-sm * 2;
  padding-right: $table-cell-padding-x-sm * 2;

  &.paginator-sm {
    padding-left: $table-cell-padding-x-sm * 2;
    padding-right: $table-cell-padding-x-sm * 2;
  }
}

.collection-grid-wrapper > .paginator {
  border-top: $table-border-width $table-border-color $border-style;
  margin-top: $table-cell-padding-y;
}

@use "sass:map";

@mixin font {
  color: $body-color;
  font-size: 15px;
  font-weight: 400;

  &.active {
    color: var(--#{$prefix}primary);
  }
}

.guest-primary-nav {
  @extend .bg-white;
  @extend .navbar-light;

  min-height: $navbar-height;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  border-bottom: 1px solid $gray-200;

  .navbar-brand {
    display: flex;
    align-items: center;
    overflow: visible;
    padding: 0;
    margin: 0;

    @extend .col-9, .col-sm-6, .col-md-3, .col-xl-2;

    .navbar-brand-text {
      margin-left: map.get($spacers, 4);
    }
  }

  .navbar-nav {
    align-items: center;

    .nav-icon {
      font-size: 130%;
    }
  }

  .guest-nav-items-container {
    margin-left: 20px;
    margin-right: auto;
    flex-grow: 1;
  }

  .guest-nav-item,
  .nav-item-more {
    display: flex;
    align-items: center;
    height: $navbar-height;
    font-size: 15px;

    @include font;

    padding: 8px 20px;

    &:hover:not(.active) {
      cursor: pointer;
      background-color: $gray-100;
      text-decoration: none;
    }

    &.active {
      color: var(--#{$prefix}primary);
      border-bottom: 2px solid var(--#{$prefix}primary);
      text-decoration: none;
      padding-bottom: 7px;
      height: $navbar-height + 1px;
      margin-bottom: -1px;
    }
  }

  .nav-item-more {
    > a {
      @include font;
    }
  }
}

.customer-rev-links-collapsed {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.customer-rev-actions-dropdown {
  @extend .dropdown-menu;

  font-size: 1rem;

  a {
    @extend .dropdown-item;
  }
}

.customer-rev-links-expanded {
  display: flex;

  a {
    @extend .guest-nav-item;
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

@include portal-theme(duke_rev) {
  .guest-primary-nav {
    .navbar-brand-image {
      img {
        height: 33px;
        // might now not be tall enough
        display: block;
        margin: 0 auto;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  @include portal-theme(duke_rev) {
    .guest-primary-nav {
      .navbar-brand-image {
        margin-left: -20px;
        min-width: #{$secondary-sidenav-width};
        border-right: var(--#{$prefix}border-width)
          var(--#{$prefix}border-style) var(--#{$prefix}border-color);
      }

      .navbar-brand-text {
        margin-left: 32px;
      }
    }
  }
}

@use "sass:meta";

:root {
  --bs-link-secondary-color: #{$link-secondary-color};
  --bs-link-secondary-hover-color: #{$link-secondary-hover-color};
  --bs-input-placeholder-color: #{$input-placeholder-color};
  --maas-legend-icon: #{$primary};

  // TODO: probably move the below to a stylesheet that only storybook loads

  // TODO: maybe prefix these with bs since they're derived from bs variables
  --btn-font-size: #{$btn-font-size};
  --btn-font-weight: #{$btn-font-weight};
  --btn-font-family: #{$btn-font-family};
  --#{$prefix}headings-font-family: #{meta.inspect($headings-font-family)};

  $color-variants: (
    "blue": $blues,
    "purple": $purples,
    "red": $reds,
    "yellow": $yellows,
    "green": $greens,
    "cyan": $cyans,
  );

  @each $palette, $variants in $color-variants {
    @each $color, $value in $variants {
      --bs-#{$color}: #{$value};
    }
  }
}

@include portal-theme(duke_rev, true) {
  @include rfs($font-size-base-duke, --#{$prefix}body-font-size);

  --#{$prefix}body-font-family: #{meta.inspect($font-family-base-duke)};
  --#{$prefix}headings-font-family: #{meta.inspect($headings-font-family-duke)};
  --#{$prefix}primary: #{$primary-duke};
  --#{$prefix}primary-rgb: #{to-rgb($primary-duke)};
  --#{$prefix}success: #{$success-duke};
  --#{$prefix}success-rgb: #{to-rgb($success-duke)};
  --#{$prefix}link-color: var(--#{$prefix}primary);
  --#{$prefix}link-color-rgb: #{to-rgb($primary-duke)};
  --#{$prefix}focus-ring-color: #{$focus-ring-color-duke};
}

@import "~bootstrap/scss/mixins";

@mixin portal-theme($name: allumia, $root: false) {
  @if $root == true {
    html.portal-#{$name}:root {
      @content;
    }
  } @else {
    html.portal-#{$name} {
      @content;
    }
  }
}

@mixin stagger-children-in($name, $duration, $base-delay: 0ms) {
  > :not(.no-animate) {
    $delay: 12 * $duration * 0.25 + $base-delay;

    animation: $name $duration $delay $default-ease-out both;
  }

  @for $i from 1 through 12 {
    > :not(.no-animate):nth-of-type(#{$i}) {
      $delay: ($i - 1) * $duration * 0.25 + $base-delay;

      animation: $name $duration $delay $default-ease-out both;
    }
  }
}

@use "sass:color";

.program-user-project-details-page .program-user-project-stat-box {
  text-align: left;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  min-height: 100px;
  border: 1px solid var(--#{$prefix}primary);
  border-radius: 2px;
  background-color: color.adjust($primary, $lightness: 50%);

  @extend .text-muted;

  h2 {
    color: var(--#{$prefix}primary);
  }

  @include media-breakpoint-down(lg) {
    margin-top: 1rem;
  }
}

//
// Bootstrap Variables
// https://github.com/twbs/bootstrap/blob/v530-dev/scss/_variables.scss
// or `code node_modules/bootstrap/scss`
//

// Top-Level Config

$enable-shadows: false;
$enable-gradients: false;
$enable-negative-margins: true;
$enable-dark-mode: false;
$enable-rfs: false;
$min-contrast-ratio: 3;
// $enable-caret: false;

// Fonts

$display-font-family: "Plus Jakarta Sans", sans-serif;
$headings-font-family: $display-font-family;
$headings-font-weight: 400;
$heavy-font-weight: 600;
$font-family-base: "Inter", sans-serif;
$font-size-base: 0.875rem;
$body-font-sm: 13px;
$body-font-xs: 12px;
$h1-font-size: 2rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.125rem; // heavier weight
$h4-font-size: 1rem;
$h5-font-size: $font-size-base; // heavier weight
$h6-font-size: $font-size-base;

// Colors / Color Overrides

// Grays
$white: #fff;
$gray-050: #fcfcfe;
$gray-100: #f8f9fa;
$gray-200: #e8e9ed;
$gray-300: #d9dbe2;
$gray-400: #c0c3ce;
$gray-500: #a5aab8;
$gray-600: #787e90;
$gray-800: #656a7b;
$gray-850: #535765;
$gray-900: #41434e;
$gray-950: #2e3038;
$black: #1b1d22;

// new Allumia primary (not used yet)
$indigo-100: #ecf0fe;
$indigo-200: #a8c0ff;
$indigo-300: #4075ff;
$indigo-400: #3d67ff;
$indigo-500: #2253ff;
$indigo-600: #111fe4;
$indigo-700: #0e1abe;
$indigo-800: #0b1598;
$indigo-900: #091072;
$indigo-950: #060a4c;
$blue-100: #e9f0f7;
$blue-200: #d4e2ef;
$blue-400: #2f80cb;
// curent Allumia primary
$blue-500: #266eb1;
$blue-600: #1e588e; // primary active bg

$cyan-500: #37e6e6;
$cyan-600: #1bdada;
$blue: $blue-500;
$indigo: $indigo-500;
$purple: #905aed;
$red: #eb4f33;
// changed from #f5c800 for contrast reasons
$yellow: #d8b102;
$green: #188b3f;
$cyan: $cyan-500;
// $pink: #d63384;
// $orange: #fd7e14;
// $teal: #20c997;

// Theme

$primary: $blue; // switch to $indigo once we're ready for bold new styles
$info: $cyan-600;
$success: $green;
$secondary: $gray-600; // button styles for this are overridden
$light: $gray-100;

// add theme colors here
// $theme-colors-custom: ();

$body-color: $gray-950;
$input-placeholder-color: $gray-500;
$table-cell-padding-y: 1rem;
$table-cell-padding-x: 0.75rem;
$table-cell-padding-y-sm: 0.5rem;
$table-cell-padding-x-sm: 0.5rem;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);
$font-family-monospace: sfmono-regular, consolas, liberation mono, menlo,
  monospace;
$link-color: $blue;
$link-hover-color: $blue;
$link-secondary-color: $gray-600;
$link-secondary-hover-color: $gray-600;

// use secondary color for navs
$nav-link-color: $link-secondary-color;
$nav-link-hover-color: $link-secondary-hover-color;
$link-decoration: none;
$link-hover-decoration: underline;
$btn-font-family: $display-font-family;
$btn-font-weight: 500;
$btn-disabled-opacity: 1;

// btn
$btn-font-size: 0.875rem;
$btn-icon-size: 1.5rem; // also controls line height
$btn-padding-y: 5px;
$btn-padding-x: 13px;

// btn-sm
$btn-font-size-sm: $btn-font-size;
$btn-icon-size-sm: $btn-icon-size;
$btn-padding-y-sm: 1px;
$btn-padding-x-sm: 7px;

// btn-lg
$btn-icon-size-lg: 28px;

// matches button styles but could use btn-border-radius instead
$border-radius: 6px;
$border-radius-sm: 6px;
$border-radius-lg: 6px;
$border-radius-xl: 12px; // note cards
$border-radius-xxl: 24px; // large pills (on notes)
$border-radius-pill: 25rem;
$offcanvas-horizontal-width: 600px;

//
// Duke Theme
//

$primary-duke: #00789e;
$primary-hover-duke: #005984;
$success-duke: #00853f;
$font-family-base-duke: "NewsGothic", arial, sans-serif;
$display-font-family-duke: $font-family-base-duke;
$headings-font-family-duke: $display-font-family-duke;
$btn-font-family-duke: $display-font-family-duke;
$font-size-base-duke: 1rem;
$h1-font-size-duke: 3rem;
$h2-font-size-duke: 1.687rem;
$h3-font-size-duke: 1.5rem;
$h4-font-size-duke: 1.25rem;
$h5-font-size-duke: $font-size-base-duke;
$h6-font-size-duke: $font-size-base-duke;

//
// Things requiring bootstrap variables
//

@use "sass:map";
@import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/variables-dark";

$form-check-input-width: 1.2857em; // 18px / 14px font size
$form-check-padding-start: $form-check-input-width + 0.8571em; // 12 / 14
$input-group-addon-bg: $input-bg;
// these become indistinguishable from disabled buttons, but would be nice to
// give them a different treatment than the field contents.
// $input-group-addon-color: $secondary;

$dropdown-link-active-bg: $blue-200; // not right but let's come back to it
$dropdown-link-active-color: $primary;
$breadcrumb-divider-color: $gray-300;
$breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'><path d='M9.39999 17.6538L8.34616 16.6L12.9462 12L8.34616 7.40002L9.39999 6.34619L15.0538 12L9.39999 17.6538Z' fill='#{$breadcrumb-divider-color}'/></svg>");
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 1rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-item-padding-x: 0;
$alert-padding-y: 1rem;
$alert-padding-x: 1.25rem;
$alert-border-width: 0;
$alert-actions-margin: 0.875rem; // custom variable - top margin on actions
$alert-icon-margin: 0.875rem; // custom variable - right margin on icon

// override primary alerts and list group items to use the same colors as info.
$primary-bg-subtle: $info-bg-subtle;
$primary-text-emphasis: $info-text-emphasis;
$table-active-bg: $blue-100;
$table-border-radius: $border-radius-lg; // custom property

// Define theme color text-emphasis, bg-subtle, border-subtle here

$grays: (
  "050": $gray-050,
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "850": $gray-850,
  "900": $gray-900,
  "950": $gray-950,
);
$colors: map.merge(
  $colors,
  (
    "blue": $blue,
    "purple": $purple,
    "red": $red,
    "yellow": $yellow,
    "green": $green,
    "black": $black,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
    "cyan": $cyan,
    "pink": $pink,
    "orange": $orange,
    "teal": $teal,
  )
);
$utilities: (
  "column-count": (
    responsive: true,
    property: column-count,
    // add more if you need more columns
    values: 1 2 3 4,
  ),
);

// $theme-colors: map-merge($theme-colors, $theme-colors-custom);

// More Duke Theme
$component-active-bg-duke: $primary-duke;
$form-check-input-checked-bg-color-duke: $component-active-bg-duke;
$form-check-input-checked-border-color-duke: $form-check-input-checked-bg-color-duke;
$focus-ring-color-duke: rgba($primary-duke, $focus-ring-opacity);
$focus-ring-box-shadow-duke: 0 0 $focus-ring-blur $focus-ring-width
  $focus-ring-color-duke;

//
// Directly modify bootstrap maps
//

@import "~bootstrap/scss/maps";

// add theme colors transforms here

// $theme-colors-text: map-merge($theme-colors-text, ());
// $theme-colors-bg-subtle: map-merge($theme-colors-bg-subtle, ());
// $theme-colors-border-subtle: map-merge($theme-colors-border-subtle, ());

//
// Custom Variables
//

$navbar-height: 66px;
$navbar-bottom-margin: 1rem;
$base-duration: 350ms;
$base-delay: $base-duration * 0.25;
$float-offset: 18px;
$default-ease-out: $ease-out-quad;

// TODO: get this working again (see useScrollHandler)
// :export {
//   navbar-height: $navbar-height;
// }

// Z-index master list
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
//
// Note: our dropdowns and popovers cannot coincide, but if
// they could, things might get weird with overlapping
//
// Bootstrap defaults (these are already variables you can use):
// $zindex-dropdown:        1000;
//  actions-menu, unifilter, top-nav-account-dropdown,
// $zindex-sticky:          1020;
// $zindex-fixed:           1030;
// $zindex-modal-backdrop:  1040;
// $zindex-modal:           1050;
// $zindex-popover:         1060;
//  multi-check-popover, Popover,
// $zindex-tooltip:         1070;
//

// sticky headers must stack below the sidebar
$zindex-sticky-table-header: 200;
$zindex-sticky-submit-controls: 210;
// 200 < sidebar < zindex-top-nav
$zindex-sidebar: 220;
$zindex-toast: 10000;
$sidenav-width: 80px;
$secondary-sidenav-width: 218px;
$popover-nav-width: 220px;
$nav-border-radius: 8px;
$nav-popover-box-shadow: 0 1px 4px rgb(0 0 0 / 8%), 0 8px 24px rgb(0 0 0 / 5%);
$dev-env: #12683a;
$staging-env: #ffde47;
$banner-warning: $yellow-700;
$banner-danger: $red-700;
$banner-info: $cyan-700;
$avatar-default-bg-color: $gray-300;
$chart-legend-width: 280px;

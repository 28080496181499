@use "sass:math";

// Makes a slim vertical timeline, with dates on the left side of the bar and descriptions on the right
// Inspired by: https://www.bootdey.com/snippets/view/timeline-events-area
.guest-project-timeline {
  .timeline-event {
    $timeline-event-width: 120px;
    $dot-diameter: 15px;
    $line-width: 3px;
    $checkmark-width: 8.75px;
    $row-padding: 10px;

    position: relative;
    padding-left: $timeline-event-width;
    top: -$row-padding;

    &.date-hidden {
      padding-left: calc($timeline-event-width / 2);

      .timeline-event-date {
        width: 0;

        .timeline-event-check {
          /* stylelint-disable-next-line scss/operator-no-newline-after */
          left: calc(0.25 * $timeline-event-width) -
            (calc($checkmark-width / 2));
        }
      }

      .timeline-milestone-date {
        display: none;
      }
    }

    .timeline-event-date {
      position: absolute;
      width: $timeline-event-width;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: calc($timeline-event-width / 2);

      p {
        font-weight: 500;
      }

      .timeline-event-check {
        position: absolute;
        left: calc(0.75 * $timeline-event-width) - (calc($checkmark-width / 2));
        color: white;
        z-index: 3;
      }

      // the dot
      &::before {
        position: absolute;
        width: $dot-diameter;
        height: $dot-diameter;
        content: "";
        top: 50%;
        right: calc($timeline-event-width / 4);
        z-index: 2;
        margin-top: calc($dot-diameter / -2);
        margin-right: calc($dot-diameter / -2);
        border-radius: 50%;
      }

      // the line
      &::after {
        position: absolute;
        width: $line-width;
        height: 100%;
        content: "";
        right: calc($timeline-event-width / 4) - calc($line-width / 2);
        z-index: 1;
      }

      &.incomplete {
        // gray dot, gray line
        &::before,
        &::after {
          background-color: $gray-500;
        }
      }

      &.complete {
        // green dot, green line
        &::before,
        &::after {
          background-color: var(--#{$prefix}timeline-complete-bg);
        }
      }
    }

    // keep the line from sticking out the top or bottom
    &:first-child {
      .timeline-event-date::after {
        height: 50%;
        top: 50%;
      }
    }

    &:last-child {
      .timeline-event-date::after {
        height: 50%;
        bottom: 50%;
      }
    }

    .timeline-event-description {
      padding-bottom: $row-padding;
      padding-top: $row-padding;
    }

    p {
      margin-bottom: 0;
    }
  }
}

// Another vertical timeline, hooray!

.timeline-vertical {
  position: relative;
  display: flex;
  flex-direction: column;

  // difference in height between icon and card header
  $icon-voffset: 1px;
  $icon-radius: 1.4rem;
  $line-width: 4px;

  .timeline-item-wrapper {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    padding-bottom: 1rem;

    .timeline-item {
      flex-grow: 1;
    }

    .timeline-icon {
      font-size: 1rem;
      border-radius: 50%;
      height: $icon-radius * 2;
      width: $icon-radius * 2;
      flex-shrink: 0;
      display: inline-flex;
      transform-origin: center;
      align-items: center;
      justify-content: center;
      margin-top: $icon-voffset;
      z-index: 1;
      border: 4px #fff solid;
    }

    @include media-breakpoint-down(lg) {
      // align-self: flex-end;
      flex-direction: row-reverse;
      border-left: $line-width $border-color solid;

      .timeline-item {
        margin-left: math.div($icon-radius, 2);
      }

      .timeline-icon {
        margin-left: calc($icon-radius * -1 - $line-width / 2);
      }
    }

    @include media-breakpoint-up(lg) {
      width: calc(50% + $line-width / 2);

      &:nth-child(odd) {
        border-right: $line-width $border-color solid;

        .timeline-item {
          margin-right: math.div($icon-radius, 2);
        }

        .timeline-icon {
          margin-right: calc($icon-radius * -1 - $line-width / 2);
        }
      }

      &:nth-child(even) {
        align-self: flex-end;
        flex-direction: row-reverse;
        border-left: $line-width $border-color solid;

        .timeline-item {
          margin-left: math.div($icon-radius, 2);
        }

        .timeline-icon {
          margin-left: calc($icon-radius * -1 - $line-width / 2);
        }
      }
    }

    &:last-child {
      border: none;
    }
  }
}

.audit-timeline {
  .card-header a {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .timeline-item .change-row {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .change-row-changes {
      text-align: right;
    }

    .change-row-changes :nth-child(n + 2)::before {
      content: " → ";
    }
  }
}

@include portal-theme(allumia) {
  .guest-project-timeline {
    --#{$prefix}timeline-complete-bg: var(--#{$prefix}primary);
  }
}

@include portal-theme(duke_rev) {
  .guest-project-timeline {
    --#{$prefix}timeline-complete-bg: var(--#{$prefix}success);
  }
}

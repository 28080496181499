.collapsible-nav .navbar-text {
  padding: var(--#{$prefix}nav-link-padding-y)
    var(--#{$prefix}nav-link-padding-x) var(--#{$prefix}nav-link-padding-y) 0;
  white-space: nowrap;

  label {
    display: block;
    margin-bottom: 0;
  }
}

.nav-tabs {
  .collapsible-nav-controls {
    .nav-item.show .nav-link {
      border-radius: 0;
    }

    .nav-link:hover,
    .nav-link:focus {
      border-color: transparent;
    }
  }
}

.nav-underline {
  border-bottom: 1px $gray-200 solid;

  .nav-link {
    // force it to overlap border of nav
    position: relative;
    top: 1px;
    padding: 19px 20px;
    border-bottom: none;
    line-height: 1;
    color: $body-color;
    transition: color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover,
    &:focus,
    &.show {
      box-shadow: inset 0 -1px $gray-400;
    }

    &.show,
    &.active {
      font-weight: $font-weight-normal;
    }

    &.show {
      color: $body-color;
    }

    &.active {
      color: var(--#{$prefix}primary); // overrides .show body-color
      box-shadow: inset 0 -2px var(--#{$prefix}primary);
    }

    i.fa,
    i.fab {
      // hide icons for this nav style
      // TODO: probably just remove all the icons
      display: none;
    }
  }

  .nav-item-more {
    .nav-link {
      // not sure why needed
      padding-bottom: 20px;
    }

    .dropdown-item {
      i.fa,
      i.fab {
        // hide icons for this nav style
        // TODO: probably just remove all the icons
        display: none;
      }
    }
  }
}

// unused at the moment
.nav-pills {
  // this doesn't interact well with collapsing
  // width: fit-content;
  padding: 0.5rem;
  background: $table-striped-bg;
  border: 1px solid $border-color;

  .nav-link {
    padding: 0.25rem 0.5rem;

    &.active {
      background: white;
      color: var(--#{$prefix}primary);
      box-shadow: 1px 1px 1px $border-color;
    }
  }
}

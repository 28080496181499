@use "sass:map";

.minipanel {
  border: $card-border-width solid $card-border-color;

  @include border-radius($card-border-radius);

  padding: map.get($spacers, 2) map.get($spacers, 3) map.get($spacers, 2)
    map.get($spacers, 3);
}

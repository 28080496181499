.chart-container {
  @extend .card, .card-body;

  width: 100%;
  box-shadow: $box-shadow-sm;
  margin-bottom: 2rem;
}

@include media-breakpoint-up(lg) {
  .chart-container:not(:first-child) {
    margin: 0;
  }
}

.chart-title {
  @extend .text-muted;
}

.recharts-legend-item:not(:first-child) {
  margin-left: 1rem;
}

// Overrides axis labels
.recharts-label {
  text-anchor: middle;
}

.chart-and-legend-container {
  display: flex;
  gap: 1rem;

  .maas-legend {
    padding-left: 10px;
    padding-right: 30px;
  }

  .maas-legend-row-wrapper {
    max-width: $chart-legend-width;
  }

  .chart-legend-name {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 0.5rem;
  }
}

// So y axis labels don't get cut off
.recharts-surface {
  overflow: visible;
}

.color-square {
  height: 12px;
  width: 12px;
  margin-right: 8px;
  align-self: center;
}

.empty-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: $gray-500;
  font-style: italic;
  outline: 1px solid $gray-300;
}

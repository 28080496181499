$maas-element-height: 44px;

.maas-element-height {
  height: $maas-element-height;
}

.uppercase {
  text-transform: uppercase;
}

// PAGES LAYOUT

// #guest-main is here to increase precedence
#guest-main .guest-maas-columns {
  display: flex;
  flex-grow: 1;
  position: sticky;
  overflow-y: auto;
  top: 0;
  max-height: 100vh;

  .has-header & {
    // top: $navbar-height;
    max-height: calc(100vh - $navbar-height);
  }

  .sidenav-wrapper {
    padding-top: 0;

    .sidenav {
      padding-top: 8px;
    }
  }
}

.guest-maas-page-content {
  flex-grow: 1;
  padding: 2rem 2rem 2rem calc(2rem + $sidenav-width);

  .guest-maas-content-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.guest-maas-chart-page-header {
  display: flex;
  align-items: center;
  min-height: 72px;
  padding: 0 36px;
  z-index: 100; // put it in front of the animated header thing
  position: relative;
  border-bottom: 1px solid $gray-200;
}

// TABLE CUSTOMIZATION

// TODO: unify a bunch of this with regular table styles

// list-table-wrapper and list-table-inner-wrapper are load-bearing - gives this
//  precedence over generic table styles
.guest-maas-table.list-table-wrapper {
  &.maas-meter-table {
    .table {
      color: $gray-600;

      thead th:first-child {
        text-align: center;
      }

      tbody tr {
        height: 60px;
        padding-left: 50px;

        td {
          max-width: 210px;

          .editable-col-content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        // make clickable label area pretty much the whole td
        td.bulk-select-cell {
          label {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            width: 100%;
          }
        }
      }
    }
  }

  .list-table-inner-wrapper {
    font-size: $h3-font-size;

    .table {
      border-right: none;
      border-left: none;
    }

    .table thead th:first-child input {
      vertical-align: middle;
    }

    // Larger checkboxes
    input[type="checkbox"] {
      height: 18px;
      width: 18px;
      margin-top: 0;
    }

    // remove table top borders
    .table thead th,
    .table.sticky-header thead tr:first-child th::before {
      border-top: none; // what is this doing? remove?
      font-size: $body-font-sm;
      top: 0;
    }
    // bottom border of thead
    .sticky-header th::after {
      border-bottom: 1px solid $gray-300;
    }

    table:not(.table-sm) tbody tr {
      // General row stuff
      height: 130px;

      td:first-child {
        width: 48px;
      }

      // tr.table-active td {
      //   box-shadow: none !important;
      // }

      // Select column custom icons and hover behavior (show checkbox on hover).
      $trans-duration: 250ms;

      .bulk-select-cell {
        // restore padding to td (from label)
        padding: $table-cell-padding-y $table-cell-padding-x;
      }

      // redundant selectors so it's higher specificity than padding override
      .bulk-select-cell label.maas-checkbox {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        // needed to match checkbox in header for some reason
        margin-left: 1px;
        // move padding back to td
        padding: 0;
        background-color: $gray-200;
        border-radius: 100%;
        width: 48px;
        height: 48px;

        input {
          transition: opacity $trans-duration,
            transform $trans-duration ease-out;
        }

        .maas-buildings-table &::after,
        .maas-groups-table &::after {
          content: "";
          position: absolute;
          width: 48px;
          height: 48px;
          border-radius: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          background-color: $gray-200;
          opacity: 1;
          transition: opacity $trans-duration;
        }

        .maas-buildings-table &::after {
          background-image: url("images/maas/maas-building.svg");
        }

        .maas-groups-table &::after {
          background-image: url("images/maas/maas-meter-group.svg");
        }

        &.select-cell-checked::after {
          opacity: 0;
        }
      }

      &:hover {
        // TODO: move hover outline UI to global tables when we do table redesign?

        // position: relative;
        // z-index: 999; // so it's in front of the above row's bottom border
        // box-shadow: 0px 0px 0px 1px var(--#{$prefix}primary);

        .bulk-select-cell label.maas-checkbox {
          input {
            opacity: 1;
            transform: scale(1);
          }

          &::after {
            opacity: 0;
          }
        }
      }

      &:not(:hover) .bulk-select-cell label.maas-checkbox {
        &.select-cell-checked {
          input {
            opacity: 1;
            transform: scale(1);
          }

          background-image: none;
        }
      }
    }
  }
}

// Center Select All checkbox so it lines up with custom checkboxes
.maas-buildings-table,
.maas-groups-table {
  thead th input {
    margin-left: 4px;
  }
}

// FORM CUSTOMIZATION

.input-group-maas {
  height: $maas-element-height;

  .form-control {
    border-color: $gray-200;
  }

  .input-group-text {
    background-color: $gray-200;
    border-color: $gray-200;

    input i {
      color: $body-color !important;
    }
  }

  input {
    height: $maas-element-height;
    border: 1px solid $gray-200;
    box-shadow: none;
  }

  &:last-child {
    button,
    button:disabled {
      border-color: $gray-200;
      background-color: $gray-200;
    }
  }
}

// LEGEND CIRCLE ELEMENT

.maas-legend-circle {
  border-radius: 100%;
  align-self: center;
  margin-right: 0.5rem;

  &.dashed {
    border-width: 2px;
    border-style: dashed;
  }
}

// CHART PAGE ENTITY DROPDOWN

.guest-maas-entity-dropdown {
  button {
    background-image: none;
    background-color: white !important;
    color: $body-color !important;
    border: none !important;
    padding: 0 !important;
  }

  @include remove-caret;

  .guest-maas-entity-dropdown-item.active,
  .guest-maas-entity-dropdown-item:hover {
    color: black;
    background-color: $gray-200;
  }
}

// DATE PICKER

.maas-date-picker {
  .dropdown {
    .btn {
      font-family: $font-family-base;
      font-weight: 400;
    }

    @include remove-caret;
  }

  .btn.btn-primary.dropdown-toggle,
  .btn {
    height: $maas-element-height;
    background-image: none;
    border: none;
    background-color: $gray-200;

    &:hover,
    &:active {
      background-color: $gray-300;
    }
    // matches bootstrap btn styling
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

    > div {
      align-items: center;
    }
  }

  height: $maas-element-height;

  input {
    height: $maas-element-height;
  }

  .form-control {
    border-color: $gray-200;
  }

  .input-group-text {
    background-color: $gray-200;
    border-color: $gray-200;

    i {
      color: $body-color !important;
    }
  }

  .input-group {
    input {
      border: 1px solid $gray-200;
      box-shadow: none;
      height: $maas-element-height;
    }
  }

  .form-group {
    flex-shrink: 0;
  }
}

// CHART CONTROLS

.maas-chart-controls {
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 0;
  background-color: $gray-100;
  border-radius: 6px;
  margin-bottom: 1rem;
  height: 52px;

  .relative-range-controls {
    background-color: transparent;
    margin: 0;

    .range-controls-btn &:hover:not(:disabled) {
      background-color: $gray-200;
    }

    .divider.default-hidden {
      display: block;
      align-self: center;
    }
  }

  .dropdown {
    .btn {
      font-family: $font-family-base;
      font-weight: 400;
    }

    @include remove-caret;

    .dropdown-toggle {
      border: none !important;
    }
  }

  .dropdown button {
    background: transparent !important;
  }
}

// STAT CARD

.maas-stat-card {
  min-height: 76px;
  border-radius: 8px;
  padding: 15px 19px;
  margin-bottom: 4px;

  .maas-stat-card-label {
    color: $gray-600;
    font-size: $body-font-xs;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .maas-stat-card-value {
    color: var(--#{$prefix}primary);
    font-weight: 500;
    font-size: $h3-font-size;
    margin-right: 10px;
    white-space: nowrap;
  }

  .maas-stat-card-details {
    color: $gray-600;
    font-size: $body-font-sm;

    .divider {
      margin: 2px 5px 0;
      border-left: 1px solid $gray-600;
      height: 15px;
    }
  }
}

.mr-8px {
  margin-right: 8px;
}

.maas-stat-info {
  hr {
    border: 1px solid $gray-400;
  }
}

// LEGEND / CHART SIDEBAR

.maas-chart-sidebar {
  position: sticky;
  overflow-y: auto;
  width: 280px;
  padding: 24px 36px;
  font-size: $body-font-sm;
  border-right: 1px $gray-200 solid;
  top: 0;
  max-height: 100vh;

  .has-header & {
    top: $navbar-height;
    max-height: calc(100vh - $navbar-height);
  }
}

.editor-maas-chart-section {
  border-top: 1px solid #dee2e6;
  margin-left: calc(-1 * var(--bs-card-spacer-x));
  margin-right: calc(-1 * var(--bs-card-spacer-x));
  padding-left: var(--bs-card-spacer-x);
  padding-right: var(--bs-card-spacer-x);
}

.editor-maas-chart-container {
  @extend .editor-maas-chart-section;

  display: flex;
}

.editor-maas-legend-container {
  padding: 1.5rem 1.5rem 0;
  margin-left: -1rem;
  border-right: 1px solid #dee2e6;
  max-height: 550px;
  overflow-y: auto;
  width: $chart-legend-width;
}

.maas-legend {
  .maas-legend-header {
    .clickable {
      color: var(--#{$prefix}primary);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  // see below - the wrapper sort of inherits the child margins if you don't
  // wrap them in a flex container like this.
  .maas-legend-rows {
    display: flex;
    flex-direction: column;
    // gap: 0;

    .maas-legend-title {
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  // this is so there's a visible gap but no interaction area gap between the
  // the legend items. without it you get unpleasant flickers as you mouse over
  // the items.
  .maas-legend-row-wrapper {
    &.disabled {
      pointer-events: none;
    }

    &:not(.disabled) {
      cursor: pointer;
    }

    .maas-legend-row {
      display: flex;
      align-items: center;
      word-break: break-word;
      min-height: 46px;

      // margin reverses padding so that the unhovered box is in the right place
      $maas-legend-row-wrapper-padding: 20px;

      margin: 2px $maas-legend-row-wrapper-padding * -1;
      padding: 10px $maas-legend-row-wrapper-padding;
      overflow: hidden;

      .maas-legend-circle {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        align-self: center;
        margin-right: 0.5rem;
      }

      .maas-legend-icon {
        padding: 7px;
        border-radius: 100%;
        margin-left: auto;
        margin-right: -10px;
      }

      &:not(:hover) .maas-legend-icon:not(.hidden) path {
        fill: transparent;
      }
    }

    &:hover .maas-legend-row {
      background-color: $gray-200;
      border-radius: 8px;

      .maas-legend-icon {
        display: block;

        &:hover {
          background-color: $gray-300;
          cursor: pointer;
        }
      }
    }
  }
}

// ALERTS

.maas-alert {
  border: 1px solid $gray-400;
  border-radius: 12px;
  padding: 18px;
  margin-left: auto;
}

// PILL ELEMENT

.maas-group-pill {
  display: inline-block;
  background-color: $gray-200;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: $body-font-xs;

  &:hover {
    text-decoration: none;
    background-color: $gray-300;
    color: white;
  }
}

// CHART THINGS

@mixin maas-chart-overlay {
  position: absolute;
  background: rgb(255 255 255 / 40%);
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

// we have variations of this all over the place but w/e
.maas-chart-overlay-spinner {
  @include maas-chart-overlay;
}

.maas-chart-overlay-icon {
  @include maas-chart-overlay;

  padding-top: 120px;
}

.maas-chart-content-column {
  flex-grow: 1;
  // without this sidebar doesn't get tall enough
  // needs to be min-height or tooltips will get cut off
  min-height: 100%;
  // need to hide x-overflow for the chart to know to resize itself
  // need to hide y-overflow or we get double-scrollbars
  overflow: hidden;
  padding: 1rem;
  // But need to show y-overflow in case the chart is taller than the page.
  // doesn't seem to be creating the double-scrollbar issue.
  overflow-y: auto;
}

.maas-chart-wrapper {
  position: relative;
  width: 100%;
  // TODO: make the height dynamic once we've have a way to figure out how
  // big our content area is
  height: 500px;

  // max-height: 600px;
  // min-height: 300px;
}

.maas-chart {
  .maas-line path {
    transition: opacity 200ms linear, filter 200ms linear,
      fill-opacity 200ms linear, stroke-width 200ms ease-out;
  }

  // if any line is focused
  &.maas-chart-focused {
    // emphasize the focused line/area
    .maas-line-focused {
      .recharts-line-curve,
      .recharts-area-curve {
        stroke-width: 2;
      }

      .recharts-area-area,
      .recharts-rectangle {
        fill-opacity: 1; // default is 0.6 - makes hovered stuff pop
      }
    }

    // and deemphasize all the other ones
    .maas-line:not(.maas-line-focused) {
      // note that we don't do this for .recharts-area-curve because in
      // area-mode only the primary entity has a curve and we want it to stay
      // pretty visible
      .recharts-line-curve,
      .recharts-area-area,
      .recharts-rectangle {
        opacity: 0.4; // to kinda put it behind the focused one
        filter: saturate(60%); // send it back to kansas
      }
    }
  }

  // Chart Tooltips

  .recharts-tooltip-wrapper {
    padding: 20px; // designs say 26px but it seems like too much
    border-radius: 24px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 25%);
    background: white;
    z-index: $zindex-tooltip;

    .maas-tooltip-header {
      margin-bottom: 16px; // designs say 24px
      font-size: $body-font-sm;
      text-transform: uppercase;
      color: $gray-400;
    }

    .maas-tooltip-row {
      display: flex;
      align-items: center;
      margin-bottom: 12px; // designs say 16px
      font-size: $body-font-sm;

      &:last-child {
        margin-bottom: 0;
      }

      .maas-legend-circle {
        width: 14px;
        height: 14px;
      }
    }
  }

  // to deal with this nonsense:
  // https://github.com/recharts/recharts/blob/415a554af499fc71dc1b58322c786f526d9f4321/src/component/Tooltip.tsx#L273
  //
  // Recharts apparently thinks this tooltip is a modal? It's adding a tabindex
  // to it, so it can be dismissed with the escape key (at which point it will
  // just pop back up if you move your mouse.) This also causes the tooltip to
  // steal focus - hope you weren't halfway through specifying a date range.
  //
  // This below doesn't solve any of this but at least gets rid of the stupid
  // blue outline in Chrome.
  .recharts-tooltip-wrapper:focus {
    outline: none;
  }
}

// Chart Scrubber
// (in theory not necessarily tied to MaaS)
// placeholder. alternative html version in git history.

.relative-range-scrubber {
  .rrs-svg {
    width: 100%;
    height: 12px;
  }

  .rrs-cursor {
    transform: translateX(0.5px);
    fill: var(--#{$prefix}primary);
    stroke: var(--#{$prefix}primary);
    transition: all ($base-duration * 0.75);
  }

  .rrs-line {
    stroke: #ccc; // shrug. same as milestone borders
    stroke-width: 2px;
  }

  .rrs-tick {
    // doesn't work for some reason - would need to use translate
    transition: all ($base-duration * 0.75);
  }

  // because half the stroke is outside the canvas
  .rrs-start-tick {
    transform: translateX(1px);
  }

  .rrs-end-tick {
    transform: translateX(-1px);
  }
}
// maas-specific stuff
.relative-range-scrubber.maas-chart-scrubber {
  margin: 0 50px 0 70px; // matches chart

  .rrs-line {
    stroke: $gray-300;
  }
}

// REPORTING THINGS

.maas-download-modal {
  .modal-header {
    background-color: $gray-200;
    border-bottom: none;
  }

  ul {
    .nav-link {
      cursor: pointer;
      color: var(--#{$prefix}primary);

      &:hover {
        // bootstrap's tab hover color
        color: #104c76;
      }
    }

    background-color: $gray-200;

    li:first-child {
      margin-left: 1rem;
    }

    li:last-child {
      margin-right: 1rem;
    }
  }
}

// LOADING SKELETON

@keyframes skeleton-scale {
  from {
    background-position: 0% 100%;
  }

  to {
    background-position: 100% 0%;
  }
}

@mixin skeleton {
  display: inline-block;
  height: 8px;
  width: 100%;
  border-radius: 8px;
  margin: 4px 0;
  background: linear-gradient(
    90deg,
    $gray-400 10.4%,
    rgb(210 216 224 / 30%) 100%
  );
  background-size: 200% 200%;
  animation: skeleton-scale 1.5s infinite linear alternate;
  background-position: 0% 100%;
}

.skeleton {
  @include skeleton;
}

.skeleton-sm {
  @include skeleton;

  height: 6px;
}

.skeleton-h4 {
  @include skeleton;

  height: 12px;
  margin: 6.6px 0;
}

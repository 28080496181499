// Layout - Body and other top-level layout styles go in here

#main {
  $side-padding: 24px; // container-fluid adds an additional 8px

  flex-grow: 1;
  padding: 0 $side-padding 0 calc($sidenav-width + $side-padding);

  .has-header & {
    padding-top: $navbar-height;
  }

  @include media-breakpoint-up(md) {
    &.secondary-sidenav-expanded {
      padding-left: calc(
        $sidenav-width + $secondary-sidenav-width + $side-padding
      );
    }
  }
}

// TODO: The scroll-padding stuff is over-scrolling in modals.
// https://app.shortcut.com/allumia/story/15017/auto-scrolling-in-modals-is-over-scrolling

// Top scrolling limit is just below navbar when linking to anchors on a page.
html {
  scroll-padding-top: calc($navbar-height + 1rem);
}

// compensate for sticky header
.table.sticky-header {
  scroll-padding-top: calc($navbar-height + 1rem + 40px);
}

// No navbar in modals though
.modal-dialog {
  scroll-padding-top: var(--#{$prefix}modal-margin);
}

.focusable {
  // switched to outline as border changes the size of the element
  // only transitioning color as width moves in 1px jumps
  // this may have bugs on mount?
  // We can remove the transition if so
  transition: outline-color 500ms ease-in;
  outline: transparent solid 3px;

  &:focus {
    outline: var(--#{$prefix}primary) solid 3px;
    transition: none; // just fade out, not in
  }
}

// Use as a last resort! see z-index css variables in _variables.scss
.zindex-100 {
  position: relative;
  z-index: 100;
}

// A bunch of rows that kind of act like a table
.comparison-form {
  margin-bottom: $spacer;
  color: $table-color;
  background-color: $table-bg;
  border-bottom: calc(2 * $table-border-width) solid $table-border-color;

  .def,
  .item {
    padding-top: $table-cell-padding-y-sm;
    padding-bottom: $table-cell-padding-y-sm;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .def {
    font-weight: bold;
  }

  .item {
    // looks pretty bad on responsive

    // &:nth-child(2) {
    //   padding-right: 2rem;
    // }
    // &:last-child {
    //   padding-left: 1rem;
    // }
  }

  .row {
    &::before {
      content: "";
      margin: 0 15px;
      width: 100%;
    }
  }

  .comparison-form-header {
    text-align: center;

    &::after {
      content: "";
      margin: 0 15px;
      width: 100%;
      border-top: calc(2 * $table-border-width) solid $table-border-color;
    }

    .comparison-form-header-label {
      white-space: nowrap;
      text-align: left;
    }
  }

  .field-editor-view {
    // removed for now because it causes the descriptions to truncate, which we
    // don't want. Correspondingly removed "nowrap" from "StaticField" in order
    // to fix the problem where a long label/value would push the edit buttons
    // into the hidden zone.
    //
    // may need to revisit this if my "fix" breaks other stuff.

    // width: 100%;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // margin-right: 15px;
  }
}

// TODO: look into this: https://developer.mozilla.org/en-US/docs/Web/CSS/contain
// WOW: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_container_queries

table.sticky-header {
  thead tr:first-child {
    th {
      position: sticky;
      background: var(--#{$prefix}body-bg);
      z-index: $zindex-sticky-table-header;

      // remove borders as the table is set to border collapse so they won't
      // stick to the header.
      border: none;
      top: 0;
      // offset top if we're under a sticky main header
      .has-header & {
        top: $navbar-height;
      }

      .modal-dialog & {
        top: calc(-1 * var(--#{$prefix}modal-padding));
      }

      .table-responsive & {
        top: 0;
      }
    }

    // recreate the borders in a way that keeps them next to the sticky
    // element
    //
    // TODO: why did we need :before? some double-sticky thing?
    th::before {
      top: -1px;
      border-top: $table-border-width $table-border-color solid;
    }

    th::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      border-bottom: $table-border-width $table-border-color solid;
    }
  }
}

.split-pane-row {
  display: flex;
  flex-direction: row;
}

.split-pane-divider {
  z-index: $zindex-sticky-submit-controls;
  flex: 0 0 7px;
  border-left: 1px solid $gray-400;
  border-right: 1px solid $gray-400;
  cursor: col-resize;
}

.split-pane-right,
.split-pane-left {
  z-index: $zindex-sticky-submit-controls;
}

.recharts-tooltip-custom {
  box-shadow: $box-shadow;
  background: white;
  border-radius: 6px;
  padding: 10px;

  .tooltip-label {
    font-weight: 700;
  }

  .legend-symbol {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin-right: 5px;
  }
}

.project-step-category-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0.25rem;
  margin-left: auto;
}

.project-step-category-actions > * {
  white-space: nowrap;
}

// Makes all buttons wrap at the same time
// @media only screen and (max-width: 1199px){
//   .project-step-category-actions{ width: 80px; }
// }

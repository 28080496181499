@use "sass:map";
// allow rows to be hoverable without the whole table being hoverable - needed
// for grouped tables
tr.table-hover:hover > * {
  --#{$prefix}table-color-state: var(--#{$prefix}table-hover-color);
  --#{$prefix}table-bg-state: var(--#{$prefix}table-hover-bg);
}

.list-table-wrapper {
  // puts margin below table. might be better to do this somewhere else.
  // TODO: this doesn't work on guest pages
  margin-bottom: map.get($spacers, 4);

  .list-table-inner-wrapper {
    // TODO: only for non-sm tables?
    border: $table-border-width $table-border-color solid;
    border-bottom: 0;
    border-top-left-radius: $table-border-radius;
    border-top-right-radius: $table-border-radius;
  }

  .table {
    // overrides markdown table style
    margin-bottom: 0;
  }

  // This is for a table within a table (ie documents on a milestone)
  &.sub-table .position-relative {
    z-index: 1;
  }

  // Stuff applied to list-table ONLY when it's in a list-table-wrapper
  // - TODO: this is confusing, we should move some of it out and add a new
  //   table class for the rest (entity-table or something)
  .list-table {
    // override hover bg (designs say gray-050 but maybe too subtle?)
    --bs-table-hover-bg: var(--bs-gray-100);

    tr.table-active {
      --bs-table-hover-bg: #dbe9f6;
    }

    tr {
      transition: background-color 220ms;
      // seems to help with flickering on .table-active (?)
      backface-visibility: hidden;
    }

    th,
    td {
      vertical-align: middle;
    }

    // TODO: we should probably make this styling opt-in rather than opt-out
    thead th:not(.table-group-heading, .bulk-select-cell) {
      color: var(--bs-link-secondary-color);
      font-weight: $heavy-font-weight;
      text-transform: uppercase;
      font-size: $body-font-xs;

      &.column-sort.column-selected,
      // for when the column sorter isn't the th itself (e.g. contacts name)
      .column-sort.column-selected {
        color: var(--bs-body-color);
      }
    }

    // center the column sort controls if the heading wants to be centered
    .text-center .column-sort-controls {
      justify-content: center;
    }

    td {
      transition: background-color 220ms, border-color 220ms;
      backface-visibility: hidden;
    }

    tr.clickable-row {
      cursor: pointer;
    }

    .table-disabled {
      &:hover > * {
        --#{$prefix}table-color-state: var(--#{$prefix}table-color);
        --#{$prefix}table-bg-state: var(--#{$prefix}table-bg);
      }
    }

    th.actions-column {
      text-align: right;

      &:first-child {
        text-align: left;
      }
    }

    thead tr:first-child th {
      white-space: nowrap;
      text-overflow: ellipsis; // doesn't work but might be doable
    }

    // double the outer padding on first and last cell of each row
    tr > :first-child {
      padding-left: $table-cell-padding-x * 2;
    }

    tr > :last-child {
      padding-right: $table-cell-padding-x * 2;
    }

    &.table-sm {
      tr > :first-child {
        padding-left: $table-cell-padding-x-sm * 2;
      }

      tr > :last-child {
        padding-right: $table-cell-padding-x-sm * 2;
      }
    }

    // round the first and last cells on top
    thead tr:first-child th:first-child {
      border-top-left-radius: $table-border-radius;
    }

    thead tr:first-child th:last-child {
      border-top-right-radius: $table-border-radius;
    }

    // for group headings within grouped tables
    td.table-group-heading,
    th.table-group-heading {
      color: $table-striped-color;
      background-color: $table-striped-bg;
      border-radius: 0;
    }
  }

  .busy-overlay {
    position: absolute;
    overflow: hidden;
    top: 50px;
    left: auto;
    right: auto;
    width: 100%;
    height: 100%;
    // might make more sense to use a bootstrap variable?
    background-color: rgb(255 255 255 / 40%);
  }
}

.column-sort {
  cursor: pointer;

  .column-sort-controls {
    display: flex;
    align-items: center;

    .column-sort-icon {
      margin-right: -4px;
      padding-left: 0.25rem;
    }
  }

  &.text-end {
    .column-sort-controls {
      justify-content: flex-end;
    }
  }
}

.no-results-placeholder {
  .list-table-wrapper & {
    margin: 13px 10px;
  }

  padding: 31px 0;
  border-radius: $border-radius;
  border: 1px dashed $table-border-color;
  text-align: center;

  .no-results-placeholder-text {
    font-size: $h3-font-size;
    color: $text-muted;
  }
}

// add negative margin to buttons in tables so they can reclaim some padding and
// not stretch their cells
// feel free to get rid of this if it causes problems
.table-btn {
  margin-top: -1 * $table-cell-padding-y;
  margin-bottom: -1 * $table-cell-padding-y;
}

.table-sm .table-btn {
  margin-top: -1 * $table-cell-padding-y-sm;
  margin-bottom: -1 * $table-cell-padding-y-sm;
}

// take all padding away from td/th and give it to the label
// TODO: probably move this to its own file
table.table.list-table {
  .bulk-select-cell {
    padding: 0;

    label {
      display: block;
      padding: $table-cell-padding-y $table-cell-padding-x;
    }
  }

  tr > .bulk-select-cell:first-child label {
    padding-left: $table-cell-padding-x * 2;
  }

  tr > .bulk-select-cell:last-child label {
    padding-right: $table-cell-padding-x * 2;
  }

  &.table-sm {
    .bulk-select-cell {
      padding: 0;

      label {
        padding: $table-cell-padding-y-sm $table-cell-padding-x-sm;
      }
    }

    tr > .bulk-select-cell:first-child label {
      padding-left: $table-cell-padding-x-sm * 2;
    }

    tr > .bulk-select-cell:last-child label {
      padding-right: $table-cell-padding-x-sm * 2;
    }
  }
}

.bulk-actions-bar {
  display: flex;
  align-items: center;
  gap: map.get($spacers, 2);

  // animation stuff: see float-in in _animation.scss
  opacity: 1;
  transform: translateY(0);

  // TODO: would be nice to give each of these a delay. Could do it by making
  // bulk actions accept props and adding .transition-delay-n classes to
  // animation.scss. Or with ReactTransitionGroup.
  transition: opacity $base-duration $default-ease-out,
    transform $base-duration $default-ease-out;

  &.invisible {
    opacity: 0.001;
    transform: translateY($float-offset);
  }
}

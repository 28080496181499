.disclosure-text {
  cursor: pointer;
}

.disclosure {
  transition: transform 200ms;
  color: $link-color;
  cursor: pointer;

  &.disclosure-expanded {
    transform: rotate(90deg);
  }
}

// app-level animation utility classes
//
// Note that a bunch of these mess up the stacking context. they shouldn't be
// used on elements that contain popper elements (dropdowns, tooltips, popovers)
// where the popped element would overlap a _different_ element that also has an
// animation applied. for instance you shouldn't have an element that contains
// dropdowns directly above another animated element if the dropdowns would
// reach into the latter.
//
// In Chrome this is true for any transition animation changes the opacity. In
// Safari it seems like transform has this effect as well.

// just opacity
//
.fade-in {
  animation: fade-in $base-duration $default-ease-out both;
}

.rows-in {
  @include stagger-children-in(fade-in, $base-duration * 0.75);
}

@keyframes fade-in {
  from {
    opacity: 0.001;
  }
}

// opacity and translate up
//
.float-in {
  opacity: 1;
  animation: float-in $base-duration $default-ease-out both;
}

.grid-in {
  @include stagger-children-in(float-in, $base-duration);
}

@keyframes float-in {
  from {
    opacity: 0.001;
    transform: translateY($float-offset);
  }
}

// more extreme version of float-in
//
.card-in {
  animation: card-in $base-duration $default-ease-out both;
}

.cards-in {
  @include stagger-children-in(card-in, $base-duration);
}

@keyframes card-in {
  from {
    opacity: 0.001;
    transform: translateY($float-offset * 2.5);
  }
}

// translate down
//
.drop-in {
  animation: drop-in $base-duration $default-ease-out both;
}

@keyframes drop-in {
  from {
    opacity: 0.001;
    transform: translateY(-1 * $float-offset);
  }
}

// translate right
//
.slide-in {
  animation: slide-in $base-duration $default-ease-out both;
}

.slide-children-in {
  @include stagger-children-in(slide-in, $base-duration * 0.5);
}

@keyframes slide-in {
  from {
    opacity: 0.001;
    transform: translateX(-1 * $float-offset);
  }
}

// formerly "emphasize" - scales and fades in
.scale-in {
  animation: scale-in $base-duration $default-ease-out;
}

@keyframes scale-in {
  0% {
    transform: scale(1.2, 1.2);
    opacity: 0.5;
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

// delay utility class
//
@for $i from 1 through 8 {
  .delay-#{$i} {
    animation-delay: $base-delay * $i;
  }
}

.duration-200 {
  animation-duration: $base-duration * 2;
}

.duration-75 {
  animation-duration: $base-duration * 0.75;
}

.duration-50 {
  animation-duration: $base-duration * 0.5;
}

.item-enter {
  opacity: 0;
  transform: scale(0.9);
}

.item-enter-active {
  opacity: 1;
  // transition: opacity 300ms ease-in;
  transform: translateX(0);
  transition: opacity 300ms ease-in, transform 300ms;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  // transition: opacity 300ms ease-in;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

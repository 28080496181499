#top-nav {
  @extend .navbar-light;
  @extend .bg-white;

  min-height: $navbar-height;
  padding: 0 20px;
  border-bottom: 1px solid $gray-200;

  .navbar-toggler {
    margin-right: auto;
    border-color: transparent !important;

    &:focus {
      box-shadow: none;
    }
  }

  .navbar-collapse.collapse {
    .site-search-bar {
      display: flex;
      width: 75%;

      form {
        flex-shrink: 0;
      }

      .site-search-text-field {
        @include media-breakpoint-up(lg) {
          width: 75%;
        }

        @include media-breakpoint-up(xl) {
          width: 50%;
        }
      }
    }

    .navbar-nav {
      padding-left: 1rem;
      margin-left: auto;

      .nav-item {
        height: $navbar-height;
        width: 100%;
        display: flex;
        align-items: center;
        white-space: nowrap;
        padding: 0 20px;
        color: $body-color;
        transition: background-color $base-duration * 0.5 ease-in;

        &:hover:not(.active, .no-hover-state) {
          cursor: pointer;
          background-color: $gray-100;
          text-decoration: none;
        }

        &.active {
          color: var(--#{$prefix}primary);
          border-bottom: 2px solid var(--#{$prefix}primary);
          text-decoration: none;
          padding-top: 1px;
          height: $navbar-height + 1px;
          margin-bottom: -1px;
        }
      }

      .nav-admin-dropdown {
        padding: 0;
        // put nav-item styling on <a> so the whole area is clicky
        a.dropdown-toggle {
          @extend .nav-item;

          &::after {
            display: none;
          }
        }

        .dropdown-item {
          font-size: 15px;
        }
      }

      .releases-link {
        position: relative;

        sup {
          position: absolute;
          right: 0;
          top: 3px;

          span {
            font-weight: 400;
            font-size: 110%;
            opacity: 0.85;
          }
        }
      }
    }
  }

  // SMALL SCREEN
  @include media-breakpoint-down(md) {
    // Styling for hamburglar nav menu
    &.show {
      padding-top: 4.5px;
    }

    .site-search-bar {
      width: 100% !important;
      padding: 10px 1rem 0;

      .site-search-text-field {
        flex-grow: 1;
      }
    }

    .navbar-nav {
      margin-top: 10px;
    }

    // Hide one of the account icons b/c bootstrap weirdness, and re-style the
    // visible one
    .hide-on-small {
      display: none;
    }

    .d-md-none .nav-item {
      max-width: 40px;
      margin-right: 1rem;
    }
  }

  .form-control,
  .input {
    height: 44px;
    background-color: var(--bs-gray-100);
    border: none;
    border-radius: 6px;
  }

  select.form-control {
    background-image: url("images/chevron-down.svg");
    background-repeat: no-repeat;
    background-position: 90% 10px;
    padding-right: 30px;
  }

  .dropdown-menu {
    border-radius: $nav-border-radius;
    box-shadow: $nav-popover-box-shadow;
  }
}

@use "sass:map";

.page-heading-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: map.get($spacers, 2);

  .page-heading-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-right: map.get($spacers, 3);
  }

  .page-heading-title .badge {
    margin-left: map.get($spacers, 2);
  }

  h1.page-heading-title .badge {
    margin-left: map.get($spacers, 3);

    --bs-badge-font-size: 50%;
  }

  h2.page-heading-title .badge {
    --bs-badge-font-size: 14px;
  }

  h3.page-heading-title .badge {
    --bs-badge-font-size: 12px;
  }

  .page-heading-actions {
    display: flex;
    align-items: center;
    // horizontal gap between buttons
    gap: map.get($spacers, 2);
  }

  &.page-heading-wrapper-h1,
  &.page-heading-wrapper-h2 {
    // vertical gap between title and nav
    margin-bottom: map.get($spacers, 3);

    // code here is to add top padding to the actions but only if they wrap
    // actions get floated to the right
    $wrap-top-margin: 2;
    // negative margin to offset the top margins of children
    margin-top: -1 * map.get($spacers, $wrap-top-margin);
    margin-right: 0;
    // add to top-margin to direct children so there's space between them if
    // they flex-wrap
    > * {
      margin-top: map.get($spacers, $wrap-top-margin);
    }

    // Temporarily stop floating them right until we've got table width under control

    // .page-heading-title {
    //   // align buttons to the right
    //   flex-grow: 1;
    // }

    // .page-heading-actions {
    //   // put most important thing on the outside
    //   flex-direction: row-reverse;
    // }
  }
}

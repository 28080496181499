.release-notes-card {
  .card-header {
    .release-link {
      display: none;
    }

    &:hover .release-link {
      display: inline;
    }
  }

  .markdown {
    img {
      max-width: 100%;
      border: 1px #ccc solid;
    }
  }
}

// Shared styling for Editor (TopNav.scss) and Guest (GuestNav.scss)

.nav-account-dropdown {
  .dropdown-toggle::after {
    display: none;
  }

  &.show .nav-account-icon {
    outline: 5px solid $gray-200;
  }

  .nav-account-icon {
    @include hover-outline;
  }

  .nav-account-name {
    font-size: 15px;
  }

  .nav-account-email {
    font-size: 13px;
    color: $secondary;
  }
}

// TODO: hover animation?
.navbar-brand {
  margin-right: 16px;

  .logo-link {
    img {
      margin-right: 16px;
    }

    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

@keyframes ominous {
  to {
    color: $danger;
    transform: scale(1.4);
  }
}

.possess-button {
  animation: ominous 2500ms ease-in-out 2000ms infinite alternate;
  transform-origin: center;

  i {
    font-size: 15px;
  }
}

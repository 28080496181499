.sidenav-wrapper {
  display: flex;
  position: fixed;
  z-index: $zindex-sidebar;

  .has-header & {
    padding-top: $navbar-height;
  }

  .sidenav {
    z-index: 2; // in front of secondary sidenav
    width: $sidenav-width;
    padding-top: 15px;
    background-color: $gray-100;
    flex-shrink: 0;

    .sidenav-sticky {
      position: relative;
      top: 0;
      padding-bottom: 52px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100vh;

      .has-header & {
        height: calc(100vh - $navbar-height);
      }

      .sidenav-navlink {
        margin-bottom: 2px;
        padding: 0 9px;
        border-radius: 16px;
        text-decoration: none;
        text-align: center;

        .sidenav-icon {
          width: 54px;
          height: 40px;
          border-radius: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color $base-duration * 0.5 linear;

          svg path {
            fill: $gray-600;
            transition: fill $base-duration * 0.5 ease-out;
          }
        }

        .sidenav-title {
          color: $body-color;
          text-decoration: none;
          text-align: center;
          font-size: 11px;
          margin-top: 6px;
        }

        &:hover,
        &.hover {
          cursor: pointer;

          .sidenav-icon {
            background-color: $gray-200;
          }
        }

        &.active {
          .sidenav-icon {
            background-color: $dropdown-link-active-bg;

            path {
              fill: var(--#{$prefix}primary);
            }
          }
        }
      }
    }

    .sidenav-menu-icon {
      min-height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 100%;
      border: none;
      transform: rotate(-180deg);
      background-color: $gray-300;

      &:hover {
        background-color: $gray-400;
      }

      &.expanded {
        background-color: transparent;
        transform: rotate(0deg);

        &:hover {
          background-color: $gray-200;
        }
      }
    }

    // Hide menu-toggler on small screen
    @include media-breakpoint-down(md) {
      .sidenav-menu-icon {
        display: none;
      }
    }

    .sidenav-item-pair {
      height: 61px;
    }
  }

  .secondary-sidenav {
    width: $secondary-sidenav-width;
    padding-top: 36px;
    color: $body-color;
    border-right: 1px solid $gray-300;
    z-index: 1; // behind main sidenav
    background: #fff;

    $transition-duration: $base-duration * 0.5;

    // slide-out transition on hide
    position: absolute;
    transform: translateX(-100%);

    &.expanded {
      position: relative;
      transform: translateX(0);
      transition: transform $transition-duration ease-out;
    }

    transition: transform $transition-duration ease-in;

    // Never show secondary nav on small screen, and hide menu toggler
    @include media-breakpoint-down(md) {
      &.expanded {
        display: none;
      }
    }

    .secondary-sidenav-sticky {
      display: flex;
      flex-direction: column;
      position: relative;
      top: 0;
      overflow-x: hidden;
      overflow-y: auto;
      font-size: 15px;
      padding-top: 4px;
      height: 100vh;

      .has-header & {
        height: calc(100vh - $navbar-height);
      }

      .secondary-sidenav-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .secondary-sidenav-title {
          padding-left: 36px;
          font-weight: 600;
          margin-bottom: 11px;
        }

        .secondary-sidenav-item {
          width: 186px;
          min-height: 39px;
          margin: 2px 16px;
          padding: 11px 20px;
          color: $body-color;

          &:hover {
            border-radius: 100px;
            background-color: $gray-100;
            text-decoration: none;
          }

          &.active {
            border-radius: 100px;
            color: var(--#{$prefix}primary);
            background-color: $dropdown-link-active-bg;
          }
        }
      }
    }
  }
}

.sidenav-menu-popover {
  box-shadow: $nav-popover-box-shadow;
  border-radius: $nav-border-radius;
  font-size: 15px;
  width: $popover-nav-width;

  .list-group-flush {
    border-radius: $nav-border-radius;
  }

  .popover-arrow {
    display: none;
  }

  &.show.fade {
    transition: opacity $base-duration * 0.5 linear;
  }

  &:not(.show) {
    transition: none;
  }

  // invisible extension of popover div to help maintain hover state with diagonal mouse movements
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: calc($popover-nav-width - 4px);
    width: 26px;
    height: 100%;
    clip-path: polygon(0 100px, 100% 0, 100% 100%, 0 300px);
    cursor: pointer;
  }

  .sidenav-menu-popover-content {
    min-width: $popover-nav-width;
    padding: 8px 0;

    .sidenav-popover-group {
      display: flex;
      flex-direction: column;
      padding: 20px 6px;

      &:not(:last-child) {
        border-bottom: 1px solid $gray-200;
      }

      .sidenav-popover-title {
        padding: 0 34px;
        font-weight: 600;
        color: $body-color;
        margin-bottom: 11px;
      }

      .sidenav-popover-item {
        padding: 10px 34px 10px 0;
        margin: 2px 0;
        text-decoration: none;
        color: $body-color;
        white-space: nowrap;

        &:not(.active) {
          padding-left: 34px;

          svg {
            display: none;
          }
        }

        &.active,
        &.active:hover {
          background-color: $dropdown-link-active-bg;
          border-radius: 100px;
          color: var(--#{$prefix}primary);

          svg {
            path {
              fill: var(--#{$prefix}primary);
            }

            width: 34px;
            display: inline;
          }
        }

        &:hover {
          background-color: $gray-100;
          border-radius: 100px;
        }
      }
    }
  }
}

// Hides sidenav menu items and their corresponding popovers depending on screen
// size and whether the secondary nav is open
@include media-breakpoint-up(md) {
  .sidenav-item-pair.expanded {
    .sidenav-item-with-menu-popover {
      display: none;
    }
  }

  .sidenav-menu-popover.expanded {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .sidenav-item-pair .sidenav-item-with-tooltip {
    display: none;
  }

  .sidenav-tooltip {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .sidenav-item-pair {
    &:not(.expanded) .sidenav-item-with-tooltip {
      display: none;
    }
  }

  .sidenav-tooltip {
    &:not(.expanded) {
      display: none;
    }
  }
}

//
// Inter (body font)
// https://gwfh.mranftl.com/fonts/inter?subsets=latin
//

/* inter-regular - latin */
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url("fonts/inter/inter-v13-latin-regular.woff2") format("woff2");
}

/* inter-500 - latin */
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url("fonts/inter/inter-v13-latin-500.woff2") format("woff2");
}

/* inter-600 - latin */
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url("fonts/inter/inter-v13-latin-600.woff2") format("woff2");
}

//
// Plus Jakarta Sans (display font)
// https://gwfh.mranftl.com/fonts/plus-jakarta-sans?subsets=latin
//

/* plus-jakarta-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/plus-jakarta/plus-jakarta-sans-v7-latin-regular.woff2")
    format("woff2");
}

/* plus-jakarta-sans-italic - latin */
@font-face {
  font-display: swap;
  font-family: "Plus Jakarta Sans";
  font-style: italic;
  font-weight: 400;
  src: url("fonts/plus-jakarta/plus-jakarta-sans-v7-latin-italic.woff2")
    format("woff2");
}

/* plus-jakarta-sans-500 - latin */
@font-face {
  font-display: swap;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  src: url("fonts/plus-jakarta/plus-jakarta-sans-v7-latin-500.woff2")
    format("woff2");
}

/* plus-jakarta-sans-500italic - latin */
@font-face {
  font-display: swap;
  font-family: "Plus Jakarta Sans";
  font-style: italic;
  font-weight: 500;
  src: url("fonts/plus-jakarta/plus-jakarta-sans-v7-latin-500italic.woff2")
    format("woff2");
}

/* plus-jakarta-sans-600 - latin */
@font-face {
  font-display: swap;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  src: url("fonts/plus-jakarta/plus-jakarta-sans-v7-latin-600.woff2")
    format("woff2");
}

/* plus-jakarta-sans-600italic - latin */
@font-face {
  font-display: swap;
  font-family: "Plus Jakarta Sans";
  font-style: italic;
  font-weight: 600;
  src: url("fonts/plus-jakarta/plus-jakarta-sans-v7-latin-600italic.woff2")
    format("woff2");
}

// Google Fonts URL:
// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Plus+Jakarta+Sans:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap");

//
// News Gothic (duke font)
// The first two are actually different than the ones the duke invoices use
//

@font-face {
  font-display: auto;
  font-family: NewsGothic;
  font-style: normal;
  font-weight: 400;
  src: url("fonts/news-gothic/newsgothicbt-roman.woff2") format("woff2"),
    url("fonts/news-gothic/newsgothicbt-roman.woff") format("woff");
}

@font-face {
  font-display: auto;
  font-family: NewsGothic;
  font-style: bold;
  font-weight: 700;
  src: url("fonts/news-gothic/newsgothicbt-bold.woff2") format("woff2"),
    url("fonts/news-gothic/newsgothicbt-bold.woff") format("woff");
}

@font-face {
  font-display: auto;
  font-family: NewsGothic;
  font-style: italic;
  font-weight: 400;
  src: url("fonts/news-gothic/newgotit-webfont.woff2") format("woff2"),
    url("fonts/news-gothic/newgotit-webfont.woff") format("woff");
}

@font-face {
  font-display: auto;
  font-family: NewsGothic;
  font-style: italic;
  font-weight: 700;
  src: url("fonts/news-gothic/newsgothbtbolditalic-webfont.woff2")
      format("woff2"),
    url("fonts/news-gothic/newsgothbtbolditalic-webfont.woff") format("woff");
}

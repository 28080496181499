.link-list {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.link-list li {
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  + li {
    border-left: $border-width solid $border-color;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

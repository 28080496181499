@use "sass:map";

$customer-rev-padding: 2rem;

#guest-main {
  display: flex;
  flex-direction: column;

  &.has-header {
    padding-top: $navbar-height;
  }
}

@mixin guest-page-attributes {
  height: 100vh;

  .has-header & {
    height: calc(100vh - $navbar-height);
  }

  .guest-page-controls {
    display: flex;
    align-items: flex-start;
    gap: map.get($spacers, 2);
    flex-wrap: wrap;
  }
}

// DEFAULT GUEST PAGE
.guest-page-default {
  @include guest-page-attributes;

  min-width: 100vw;
  margin: 0 auto;
  padding: 1.75rem 1rem 0;

  @include media-breakpoint-up(md) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

// MAAS GUEST PAGE
.guest-page-maas {
  @include guest-page-attributes;

  // this will likely cause problems if there are .row elements
  padding: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
}

// REV CUSTOMER GUEST PAGE
.guest-page-customer-rev {
  @include guest-page-attributes;

  padding: 0;

  .sidenav-wrapper {
    @include media-breakpoint-down(md) {
      display: none;
    }

    .has-header & {
      padding-top: 0;
    }

    .secondary-sidenav {
      padding-top: $customer-rev-padding;
      transform: none;
      margin-top: 0;

      .secondary-sidenav-sticky {
        padding-top: 0;
      }
    }
  }

  .customer-rev-dropdown-nav {
    // hide dropdown on larger screens
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .customer-rev-content {
    flex: 5 0 0 px;
    padding: $customer-rev-padding;
    max-width: 1400px;

    @include media-breakpoint-up(md) {
      padding-left: calc($secondary-sidenav-width + $customer-rev-padding);
    }
  }
}

.guest-projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 380px);
  gap: 2rem;
  justify-content: flex-start;
  align-items: flex-start;

  .guest-projects-card {
    .guest-card-link {
      &:hover {
        text-decoration: none;
      }
    }

    .list-group-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .guest-item-view-btn {
      width: 110px;
    }

    .ds-status-rejected {
      color: $dark;
    }

    .guest-projects-map-placeholder {
      width: 380px;
      height: 200px;
      background: #efefef;
    }

    .map-container {
      border-bottom: 1px $border-color solid;
    }

    .map-placeholder {
      border-bottom: 1px $border-color solid;
    }
  }
}

.guest-contact-card {
  min-width: 300px;
  min-height: 130px;
}

.guest-list-card {
  margin-bottom: 0.5rem;
  text-decoration: none !important;
  color: $body-color !important;

  @extend .action-hover;

  .guest-list-card-col {
    @extend .col-lg-2, .col-md-4, .col-6;

    padding: 0.125rem 15px;
  }

  .label {
    @extend .text-muted;
    @extend .text-small;
    @extend .mb-1;
  }
}

.ach-payment-page {
  margin: 1rem auto;
  padding: 1rem;
  max-width: 800px;
}

.guest-resource-cards {
  .guest-list-card {
    .guest-resource-col {
      padding: 0.125rem 15px;
    }
  }

  .guest-list-card + .group-header {
    margin-top: 15px;
  }
}

.guest-project-documents-card {
  .card-body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    :first-child {
      flex: 0 1 0;

      a > i {
        font-size: 1.3em;
      }
    }

    :not(:first-child) {
      flex: 1 1 0;
    }
  }
}

// ReactMarkdown wraps everything in a p tag which has bottom margin by default.
// Add "no-margin" to the class to remove.
.markdown.no-margin > p {
  margin-bottom: 0;
}

.markdown {
  ul {
    margin-left: 0;
    padding-left: 2rem;
  }

  a {
    font-weight: bold;
  }

  table {
    table-layout: fixed;
    width: 100%;

    @extend .table;
    @extend .table-bordered;
    @extend .table-sm;
  }

  pre {
    background-color: $gray-100;

    code {
      display: block;
      padding: 0.75rem 1rem;
    }
  }

  blockquote {
    color: $gray-600;
    padding: 0 1em;
    border-left: 0.25em solid $gray-500;
  }
}

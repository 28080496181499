// customize bootstrap UI elements here

input[type="color"] {
  padding: 0;
  // border: none;
  // box-shadow: none;
  padding: 0.125rem 0.25rem;
  cursor: pointer;
  max-width: 50px;

  &:focus {
    box-shadow: none;
  }
}

.code-input {
  font-family: $font-family-monospace;
  font-size: 12px;
  white-space: pre-wrap;
  color: $body-color;

  &:focus {
    color: $body-color;
  }
}

.scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.invalid-feedback {
  display: block;
}

.table-fixed {
  table-layout: fixed;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-text {
  cursor: text !important;
}

.cursor-auto {
  cursor: auto !important;
}

.rbt-input-multi .rbt-input-wrapper {
  // overrides the rbt styles needed or it's 1px too short - this still doesn't
  // seem to be quite right as the height will grow when you add the first item
  // to it.
  margin-top: 0;
}

// style the selected items in typeahead multi dropdown
.rbt.typeahead-remote-multi {
  a[id*="item-"].dropdown-item.disabled {
    // color: var(--bs-dropdown-link-active-color);
    background-color: var(--bs-dropdown-link-active-bg);
  }
}

// remove the little invalid icon for small inputs/selects
.is-invalid-mini {
  border-color: $form-feedback-invalid-color;

  &:focus {
    border-color: $form-feedback-invalid-color;
    box-shadow: 0 0 0 $input-focus-width
      rgba($form-feedback-invalid-color, 0.25);
  }
}

.hover-primary:hover {
  color: var(--#{$prefix}primary) !important;
}

@each $color, $value in $theme-colors {
  #{".hover-#{$color}"} {
    &:hover {
      color: $value !important;
    }
  }

  // white background with narrow stripes
  // .bg-success-striped, .bg-primary-striped, etc.
  $stripe-size: 8px;
  #{".bg-#{$color}-striped"} {
    background-image: repeating-linear-gradient(
      45deg,
      tint-color($value, 90%),
      tint-color($value, 90%) $stripe-size,
      rgb(0 0 0 / 0%) $stripe-size,
      rgb(0 0 0 / 0%) $stripe-size * 2
    );
    background-attachment: fixed;
  }
}

// e.g. border-lg-left
// for responsively displaying column dividers, basically
@each $breakpoint in map.keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    $left-right: "left", "right";

    @each $value in $left-right {
      .border#{$infix}-#{$value} {
        border-#{$value}: $border-width solid $border-color !important;
      }
    }
  }
}

.text-link {
  color: $link-color !important;
}

.fake-link {
  color: $link-color !important;
  cursor: pointer;

  &:hover {
    text-decoration: $link-hover-decoration;
  }
}

.cursor-help {
  cursor: help;
}

// fix for a bootstrap bug when using tabs as a div
div.nav.nav-tabs > .nav-link,
div.nav.nav-tabs > .nav-item {
  margin-bottom: -1px;
}

.placeholder-text {
  color: $input-placeholder-color;
}

@include media-breakpoint-up(sm) {
  .modal-xl {
    width: 1200px;
    max-width: 90%;
  }
}

.action-hover:hover {
  // TODO: fix hardcoded color
  background-color: #f8f9fa;
  cursor: pointer;
}

// Spinner within search field
.input-group-text-search-field {
  .spinner-border-sm {
    width: 14px;
    height: 14px;
  }

  .input-group-sm & {
    .spinner-border-sm {
      width: 13px;
      height: 13px;
      border-width: 0.18rem;
    }
  }
}

.gray-border {
  border: 1px solid $gray-300;
}

.highlight-border {
  border: 2px solid var(--#{$prefix}primary);
  border-radius: 6px;
}

/* don't let buttons or inputs block mouse events from reaching tooltip wrapper */
abbr .btn[disabled] {
  pointer-events: none;
  cursor: not-allowed;
}

abbr input[disabled] {
  pointer-events: none;
  cursor: not-allowed;

  + label {
    cursor: help;
  }
}

.rounded-0-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.loading-dots {
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }

  @keyframes ellipsis {
    0% {
      content: ".";
    }

    33% {
      content: "..";
    }

    66% {
      content: "...";
    }
  }
}

@mixin remove-caret {
  ::after {
    display: none;
  }
}

.remove-caret {
  @include remove-caret;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.vr {
  border-left: 1px solid;
}

.a-disabled {
  pointer-events: none;
  color: $btn-link-disabled-color;
}

@mixin hover-outline(
  $color: $gray-200,
  $size: 5px,
  $duration: $base-duration * 0.5
) {
  outline: 0 solid transparent;
  transition: outline-color $duration ease-in-out,
    outline-width $duration ease-in-out;

  &:hover:not(.disabled) {
    outline: $size solid $color;
  }
}

.border-text-success {
  border: solid $success;
  color: $success;
}

.border-text-danger {
  border: solid $danger;
  color: $danger;
}

.modal-content {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 50%);
}

// this is used in milestones for in-progress badge
.text-bg-primary-light {
  color: $body-color !important;
  background-color: $blue-200;
}

.border-none {
  border: none;
}

@include media-breakpoint-up(xl) {
  .ml-auto-xl {
    margin-left: auto;
  }
}

// TODO: let's get rid of this and use a z-index variable
.z-index-3 {
  z-index: 3 !important;
}

// when a selected thing has a light-blue background, all text should be primary
.dropdown-menu {
  a.active,
  a:active,
  .dropdown-item.active,
  .dropdown-item:active {
    i {
      color: var(--#{$prefix}primary) !important;
    }

    div {
      color: var(--#{$prefix}primary) !important;
    }
  }
}

.hide-arrow {
  .tooltip-arrow {
    display: none;
  }
}

input.rbt-input-main::placeholder,
.multicheck-popover-placeholder {
  color: var(--bs-input-placeholder-color);
}

// when a card is closed and it's just the header, otherwise bottom border extends past border radius curve
.card:not(:has(.card-body)) {
  .card-header {
    border-bottom: none;
  }
}

.left-align-tooltip {
  .tooltip-inner {
    text-align: left;
    max-width: none;
  }
}

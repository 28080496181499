.alert {
  &.alert-banner {
    color: $black; // remove to get the fancier colors
    display: flex;
  }

  // reverse underline hover behavior to distinguish from bold text
  .alert-link {
    color: inherit;
    text-decoration: $link-hover-decoration;

    &:hover {
      text-decoration: $link-decoration;
    }
  }

  .alert-content {
    // lines up the top line of text with the icon
    padding-top: 1px;
  }

  .alert-icon {
    margin-right: $alert-icon-margin;
  }

  .alert-actions {
    display: flex;
    margin-top: $alert-actions-margin;
    gap: map.get($spacers, 3);
    font-weight: $heavy-font-weight;

    a {
      color: inherit;

      &:hover {
        text-decoration: $link-hover-decoration;
      }
    }
  }
}

.alert-actions {
  // primary buttons inside banners match color theme
  .btn-primary {
    .alert.alert-warning & {
      @include button-variant($banner-warning, $banner-warning);
    }

    .alert.alert-danger & {
      @include button-variant($banner-danger, $banner-danger);
    }

    .alert.alert-info & {
      @include button-variant($banner-info, $banner-info);
    }
  }
}

.status-pill {
  @extend .badge;
  @extend .rounded-pill;

  &:hover,
  &:focus {
    color: inherit;
  }

  line-height: "14px";
  white-space: pre-wrap;
  max-width: 100%;
  opacity: 1;

  &.pill-bg-success {
    background-color: $success;
  }

  &.pill-bg-success-striped {
    background: repeating-linear-gradient(
      45deg,
      $success,
      $success 5px,
      #24963f 5px,
      #24963f 6px
    );
  }

  &.pill-bg-primary {
    background-color: var(--#{$prefix}primary);
  }

  &.pill-bg-dark {
    background-color: $dark;
  }

  &.pill-bg-secondary {
    background-color: $secondary;
  }

  &.pill-bg-warning {
    background-color: $warning;
  }

  &.pill-bg-danger {
    background-color: $danger;
  }
}

.static-map-modal {
  .modal-content {
    border: none;
  }

  .modal-body {
    padding: 0;
  }
}

.map-container {
  img.map {
    max-width: 100%;
  }
}

.map-placeholder {
  background-image: repeating-linear-gradient(
    45deg,
    $light,
    $light 8px,
    #efefef 8px,
    #efefef 8px * 2
  );

  .map-placeholder-text {
    text-align: center;
    padding: 2rem;

    @include font-size($h4-font-size);

    font-weight: bold;
    color: $secondary;
  }
}

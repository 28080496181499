// could just make this the default breadcrumb style if we're not using
// breadcrumbs elsewhere
.breadcrumbs-main {
  margin-top: 0.5rem; // b/c it's the top element on the page

  &:empty::after {
    // make it be the right height before content is loaded
    // not sure if better way?
    content: "\200B";
  }

  .breadcrumb-item .btn-transparent {
    --bs-btn-font-family: #{$font-family-base};
    --bs-btn-font-weight: #{$font-weight-base};
    --bs-btn-color: #{$link-secondary-color};
    --bs-btn-hover-color: #{$link-secondary-color};
    --bs-btn-disabled-color: #{$body-color};
    --bs-btn-hover-bg: #{$gray-100};
    --bs-btn-hover-border-color: #{$gray-100};
    --bs-btn-disabled-opacity: 1;

    &.disabled {
      --#{$prefix}btn-disabled-bg: transparent;
      --#{$prefix}btn-disabled-border-color: transparent;

      opacity: 1;
    }
  }

  // I think courty wanted a hover state for the disabled item at the end

  .breadcrumb-item + .breadcrumb-item::before {
    // fudge factor
    padding-top: 2px;
  }

  &.breadcrumbs-large .breadcrumb-item {
    .btn-transparent {
      --bs-btn-font-size: 16px;
    }

    + .breadcrumb-item::before {
      // bigger chevron-right to match bigger font
      --bs-breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24 ' fill='none'><path d='M9.39999 17.6538L8.34616 16.6L12.9462 12L8.34616 7.40002L9.39999 6.34619L15.0538 12L9.39999 17.6538Z' fill='%23d9dbe2'/></svg>");

      padding-top: 0;
    }
  }
}

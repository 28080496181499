@include portal-theme(allumia) {
  .btn-primary {
    --#{$prefix}btn-hover-bg: #{$blue-400};
    --#{$prefix}btn-hover-border-color: #{$blue-400};
    --#{$prefix}btn-active-bg: #{$blue-600};
    --#{$prefix}btn-active-border-color: #{$blue-600};
  }
}

@include portal-theme(duke_rev) {
  .focus-ring:focus {
    outline: 0;
    // By default, there is no `--bs-focus-ring-x`, `--bs-focus-ring-y`, or `--bs-focus-ring-blur`, but we provide CSS variables with fallbacks to initial `0` values
    box-shadow: var(--#{$prefix}focus-ring-x, 0)
      var(--#{$prefix}focus-ring-y, 0) var(--#{$prefix}focus-ring-blur, 0)
      var(--#{$prefix}focus-ring-width) var(--#{$prefix}focus-ring-color-duke);
  }

  .form-control {
    @include font-size($font-size-base-duke);

    &:focus {
      border-color: $focus-ring-color-duke;
      box-shadow: $focus-ring-box-shadow-duke;
    }
  }

  .form-select {
    &:focus {
      border-color: $focus-ring-color-duke;
      box-shadow: $focus-ring-box-shadow-duke;
    }
  }

  .form-check-input {
    &:checked,
    &[type="checkbox"]:indeterminate {
      background-color: $form-check-input-checked-bg-color-duke;
      border-color: $form-check-input-checked-border-color-duke;
    }

    &:focus {
      border-color: $focus-ring-color-duke;
      box-shadow: $focus-ring-box-shadow-duke;
    }
  }

  .btn {
    --#{$prefix}btn-font-family: #{$btn-font-family-duke};

    letter-spacing: 0.01rem;
  }

  .btn-primary {
    --#{$prefix}btn-bg: var(--#{$prefix}primary);
    --#{$prefix}btn-border-color: var(--#{$prefix}primary);
    --#{$prefix}btn-hover-bg: #{$primary-hover-duke};
    --#{$prefix}btn-hover-border-color: #{$primary-hover-duke};
    --#{$prefix}btn-active-bg: #{$primary-hover-duke};
    --#{$prefix}btn-active-border-color: #{$primary-hover-duke};
    --#{$prefix}btn-focus-box-shadow: 0 0 0 #{$btn-focus-width} #{$focus-ring-color-duke};
  }
}

$filecard-padding: 8px 16px;
$filecard-border-radius: 6px;
$filecard-height: 52px;

.file-upload-overlay {
  position: absolute;
  top: -0.25rem;
  bottom: -1rem;
  left: 0;
  z-index: $zindex-modal-backdrop;
  width: 100%;
  background-color: rgba($modal-backdrop-bg, 0.4);
  text-align: center;
  font-weight: bold;
  font-size: 250%;
  border: 8px #666 dashed;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-out;

  .file-upload-overlay-icon {
    transition: transform $base-duration * 0.5 $base-duration * 0.25
      $default-ease-out;
    transform: translateY($float-offset);
  }

  &.shown {
    opacity: 1;

    .file-upload-overlay-icon {
      transform: translateY(0);
    }
  }
}

.filecard,
.imagecard {
  position: relative;

  .filecard-name {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .filecard-actions {
    white-space: nowrap;
    transition: transform $base-duration * 0.5 $default-ease-out,
      opacity $base-duration * 0.5 $default-ease-out;
    opacity: 0.001;
  }

  &:hover,
  &:focus-within {
    .filecard-actions {
      opacity: 1;
      transform: translateY(0);
    }
  }

  button,
  .btn {
    color: var(--bs-gray-500);
    cursor: pointer;

    &:hover {
      color: $dark;
    }
  }

  .filecard-file-icon {
    font-size: 150%;
    margin-right: 1rem;
  }

  .progress {
    position: absolute;
    border-radius: 0;
    background-color: transparent;

    .progress-bar {
      background-color: var(--bs-gray-600);
    }
  }
}

.filecard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: $filecard-padding;
  border-radius: $filecard-border-radius;
  background-color: var(--bs-gray-100);
  height: $filecard-height;

  &.filecard-error {
    background-color: var(--bs-red-200);
  }

  &.filecard-muted {
    background-color: transparent;
    border: 1px solid var(--bs-gray-300);
  }

  .filecard-loading {
    margin-left: auto;
  }

  .filecard-actions {
    margin-left: auto;
    transform: translateY($float-offset * 0.5);
  }

  .progress {
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;

    @include border-bottom-radius($card-inner-border-radius);
  }
}

.filecard-rows {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

// for image card w/ multi uploader
.imagecard-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.imagecard {
  width: 152px;
  border: $card-border-width solid $card-border-color;

  &.filecard-error {
    border: $card-border-width solid var(--bs-red-200);
  }

  @include border-radius($card-border-radius);

  .imagecard-image-wrapper {
    width: 150px;
    min-height: 100px;
    text-align: center;
  }

  .imagecard-image {
    @include border-top-radius($card-inner-border-radius);

    height: 100%;
  }

  .imagecard-icon {
    padding-top: 12px;
    font-size: 75px;
  }

  .imagecard-body {
    border-top: $card-border-width solid $card-border-color;
    padding: $card-spacer-y * 0.5 $card-spacer-x * 0.5;
  }

  .filecard-actions {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25rem;
    transform: translateY($float-offset * -1);

    @include border-top-radius($card-inner-border-radius);

    border-bottom: $card-border-width solid $card-border-color;
    background: rgb(255 255 255 / 90%);
    text-align: right;
  }

  .progress {
    top: 0;
    left: 0;
    right: 0;
    height: 4px;

    @include border-top-radius($card-inner-border-radius);
  }
}

.single-file-input-wrapper {
  &.preview-right {
    display: flex;
    justify-content: space-between;

    .single-file-input-fields {
      min-width: 0;
      flex-grow: 1;
    }

    .single-file-input-preview {
      min-height: 120px;
      min-width: 120px;
      max-height: 120px;
      max-width: 120px;
      margin: 0.5rem;

      .img-preview,
      .img-placeholder.default {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 100%;
      }

      .img-placeholder.default {
        background-color: var(--bs-gray-200);
      }
    }
  }

  &.preview-top {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    height: 100%;

    .single-file-input-preview {
      max-width: calc(100vw - $sidenav-width);
      max-height: 350px;
      margin-bottom: 1rem;

      img {
        @include border-radius($card-inner-border-radius);
      }

      .img-preview {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .img-placeholder {
        height: 200px;
        width: auto;
        object-fit: scale-down;

        &.default {
          width: 350px;
          background-color: var(--bs-gray-200);
          @include border-radius($card-inner-border-radius);
        }
      }
    }
  }
}

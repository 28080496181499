$milestones-progress-bar-border: 2px #ccc solid;
$milestones-progress-bar-border-radius: 0.5rem;

.milestones-progress-outer-container {
  display: flex;
}

.milestones-progress-labels {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  margin-right: 0.5rem;
  justify-content: space-evenly;
  text-align: right;
}

.milestones-progress-bar-container {
  border: $milestones-progress-bar-border;
  border-radius: $milestones-progress-bar-border-radius;
  overflow: hidden;
  flex-grow: 1;
}

.milestone-phase-labels {
  display: flex;
  width: 100%;
  flex: 1 1 0px;

  .phase-label {
    transform: rotate(-45deg);
    transform-origin: bottom left;
  }
}

.milestone-step:first-of-type > .selected-border {
  border-radius: 0 0 0 $border-radius-lg;
}

.milestone-step:last-of-type > .selected-border {
  border-radius: 0 0 $border-radius-lg 0;
}

.milestones-progress-bar {
  display: flex;
  width: 100%;
  border-bottom: $milestones-progress-bar-border;

  &:last-child {
    border-bottom: none;
  }

  .milestone-phase {
    position: relative;
  }

  .milestone-step,
  .milestone-phase {
    cursor: pointer;
    min-height: 20px;
    flex: 1 1 0px;
    text-align: right;

    .step-border {
      display: inline-block;
      width: 100%;
      height: 100%;
      border-right: $milestones-progress-bar-border;
    }

    .phase-number {
      // https://stackoverflow.com/questions/6626314/center-an-item-with-position-relative
      display: inline-block;
      left: 50%;
      font-weight: 300;
      position: absolute;
      transform: translateX(-50%);
    }

    .selected-border {
      border: 2px solid black;
    }

    &:last-child {
      .step-border:not(.selected-border) {
        border: none;
      }
    }
  }
}

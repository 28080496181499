.spinner-border {
  --bs-spinner-size: 32px;
  --bs-spinner-track-color: #{$gray-300};
  --bs-spinner-cursor-color: var(--bs-primary);
  --bs-spinner-track-width: 2px;

  display: inline-block;
  width: var(--bs-spinner-size);
  height: var(--bs-spinner-size);
  border: var(--bs-spinner-track-width) solid var(--bs-spinner-track-color);
  border-right-color: var(--bs-spinner-cursor-color);
  border-radius: 100%;

  &.spinner-border-sm {
    --bs-spinner-size: 24px;
  }

  &.spinner-border-md {
    --bs-spinner-size: 32px;
  }

  &.spinner-border-primary {
    --bs-spinner-cursor-color: var(--bs-primary);
  }

  &.spinner-border-secondary {
    --bs-spinner-cursor-color: var(--bs-secondary);
  }
}

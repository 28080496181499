@use "sass:map";
// Typography - add custom text styles here

// gray links
.link-secondary {
  color: var(--#{$prefix}link-secondary-color) !important;

  &:hover {
    --#{$prefix}link-color: var(--#{$prefix}link-secondary-hover-color);
  }
}

// underline on by default when link-secondary is within text-muted
.text-muted,
.form-text,
.text-secondary,
.subtitle {
  .link-secondary {
    text-decoration: $link-hover-decoration;

    &:hover {
      text-decoration: $link-decoration;
    }
  }
}

.text-unmuted {
  color: var(--#{$prefix}body-color) !important;
}

// subtitle inside a heading
.page-heading-wrapper {
  .page-heading-title .subtitle {
    color: var(--#{$prefix}secondary-color);
    margin-left: map.get($spacers, 2);
    font-size: 0.75em;
  }

  &.page-heading-wrapper-h1 {
    .page-heading-title .subtitle {
      font-size: 0.6em;
    }
  }
}

.numeric-text {
  font-family: $font-family-monospace;
  font-size: 90%;
  color: black;
}

.nowrap {
  white-space: nowrap;
}

.text-small {
  font-size: 90%;
}

.text-90 {
  font-size: 80% !important;
}

.text-80 {
  font-size: 80% !important;
}

.text-50 {
  font-size: 50% !important;
}

.text-120 {
  font-size: 120% !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

ul.unindented,
ol.unindented {
  padding-left: 1rem;
}

.text-code {
  @extend code;
}

.body-font-sm {
  font-size: $body-font-sm;
}

// TODO: remove this
.gray-600 {
  color: $gray-600 !important;
}

// TODO: get rid of this rule - use .strong instead (?)
.font-heavy {
  font-weight: 600 !important;
}

@include portal-theme(allumia) {
  h3,
  .h3 {
    font-weight: 500;
  }

  // line heights are because these are the same size as body copy so the line
  // height needs to match or they'll look weird.
  h5,
  .h5 {
    font-weight: 500;
    line-height: $line-height-base;
  }

  h6,
  .h6 {
    line-height: $line-height-base;
  }

  b,
  strong,
  .strong {
    font-weight: 600;
  }
}

@include portal-theme(duke_rev) {
  letter-spacing: 0.025rem;

  %heading {
    font-family: #{$headings-font-family-duke};
  }

  h1,
  h2,
  h3,
  .h1,
  .h2,
  .h3 {
    letter-spacing: 0.0625rem;
  }

  h1,
  .h1 {
    @include font-size($h1-font-size-duke);
  }

  h2,
  .h2 {
    @include font-size($h2-font-size-duke);
  }

  h3,
  .h3 {
    @include font-size($h3-font-size-duke);
  }

  h4,
  .h4 {
    @include font-size($h4-font-size-duke);
  }

  h5,
  .h5 {
    @include font-size($h5-font-size-duke);
  }

  h6,
  .h6 {
    @include font-size($h6-font-size-duke);
  }

  b,
  strong,
  .strong {
    font-weight: 700;
  }
}

@include portal-theme(duke_rev) {
  a {
    --#{$prefix}link-color: var(--#{$prefix}primary);
    --#{$prefix}link-color-rgb: var(--#{$prefix}primary-rgb);
  }

  .text-primary {
    color: $primary-hover-duke !important;
  }
}

// used in ACH UI
.text-muted-small {
  font-size: 90%;
  color: $text-muted;
  margin-bottom: 0.25rem;
}

@use "sass:color";

.schedule-selector {
  display: grid;
  grid-template-columns: auto repeat(7, 1fr);
  grid-template-rows: auto repeat(24, 1fr);
  gap: 0;
  width: 100%;
  // don't try to select text
  user-select: none;

  // day headers along the top
  .schedule-day {
    text-align: center;
    padding: 0 4px;
  }

  // hour listing on the left
  .schedule-hour {
    padding: 2px 0;
    margin-right: 4px;
    text-align: right;
    color: $text-muted;
    font-size: 80%;
  }

  .schedule-cell {
    cursor: cell;
    // prevent scrolling when dragging on touch
    touch-action: none;
    height: 100%;
    width: 100%;
    border: 2px #fff solid;
    transition: background-color 180ms $ease-in-out-quad;

    $selected: rgb(89 154 242);
    $unselected: rgb(219 237 255);

    // lighter color by default
    background: $unselected;

    // selected means the cell is turned on
    &.selected {
      background: $selected;
    }

    // active means the cell is within the dragging rectangle
    &.active {
      // a cell that's active but not selected means we're disabling stuff
      // use a bg that's not quite as light as if it were unselected
      background: color.mix($selected, $unselected, 25%);

      &.selected {
        // a cell that's active AND selected means we're enabling stuff
        // use a bg that's not quite as dark as if it were selected
        background: color.mix($selected, $unselected, 75%);
      }
    }

    // hovering messes up touch devices
    @media (hover: hover) {
      // hover coloring a little more subtle so you it's easy to tell what the
      // state of the cell underneath is
      &:hover:not(.active) {
        background: color.mix($selected, $unselected, 15%);

        &.selected {
          background: color.mix($selected, $unselected, 80%);
        }
      }
    }
  }
}

@use "sass:map";
// TODO: clean this up!!

.multi-check-columns {
  // TODO: what is this doing here, it's manually set in a style tag(?)
  .multi-check-groups {
    columns: 250px 4;
  }
}

.multi-check-group {
  break-inside: avoid; // try not to column break in the middle of a group
  margin-bottom: map.get($spacers, 1);

  .multi-check-option,
  .multi-check-group-title {
    position: static;
  }

  // indent child checkboxes if parent is toggleable
  &.toggleable .multi-check-option {
    margin-left: 1.5em;
  }

  // alternative to indentation - add underlines to parent
  // &.toggleable .multi-check-group-title {
  //   border-bottom: 1px $border-color solid;
  // }
}

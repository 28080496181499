// will likely want to add some interaction states for this once it gets used in
// more places
.user-avatar {
  @include reset-text;

  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 100%;
  color: var(--bs-body-color);
  background-color: $avatar-default-bg-color;
  text-transform: uppercase;
  font-weight: 500;
  flex-shrink: 0;

  &:hover {
    text-decoration: none;
  }

  img {
    border-radius: 100%;
  }

  &.user-avatar-hover {
    // feel free to mess with this
    @include hover-outline(
      $color: $blue-300,
      $size: 2px,
      $duration: $base-duration * 0.25
    );
  }

  &.user-avatar-md {
    height: 40px;
    width: 40px;
    font-size: 16px;
    font-family: var(--bs-headings-font-family);
    line-height: 40px;
  }

  &.user-avatar-sm {
    height: 32px;
    width: 32px;
    font-size: 14px;
    line-height: 32px;
  }

  &.user-avatar-xs {
    height: 24px;
    width: 24px;
    font-size: 11px;
    line-height: 24px;
  }

  &.user-avatar-default-preview {
    height: 120px;
    width: 120px;
    font-size: 55px;
    line-height: 55px;
    font-family: var(--bs-headings-font-family);
  }
}

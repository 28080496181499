.zone-compare-form {
  .details-container {
    padding-top: 15px;
    border-top: calc(2 * $table-border-width) solid $table-border-color;

    .bold-label {
      label {
        font-weight: bold;
      }
    }
  }
}

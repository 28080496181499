.project-submission-summary {
  > section {
    break-inside: avoid;
  }
}

// TODO: can be removed and use column-count utility class instead

.project-submission-summary-md {
  @extend .project-submission-summary;

  @include media-breakpoint-up(xl) {
    column-count: 2;
  }
}

.project-submission-summary-xl {
  @extend .project-submission-summary;

  @include media-breakpoint-up(md) {
    column-count: 2;
  }
}

.relative-range-controls {
  align-items: center;
  background-color: $gray-200;
  border-radius: $border-radius;
  margin-left: 0.5rem;

  .divider {
    @extend .vr;

    margin: 0 0.5rem;
    height: 28px;

    &.default-hidden {
      display: none;
    }
  }
}

.range-controls-btn {
  padding: 5px 8px;
  border-radius: 6px;
  background-color: transparent;
  height: 34px;
  margin: 0 6px;
  color: $body-color;

  &.border {
    border: 1px solid $gray-400;
  }

  &:not(.border) {
    border: none;
  }

  &:disabled {
    color: $gray-400;
  }

  &:hover:not(:disabled) {
    background-color: $gray-300;
  }

  &.disabled {
    pointer-events: none;
    color: $gray-400;

    svg path {
      fill: $gray-400;
    }
  }
}

@use "sass:map";

.progress-bar-wrapper {
  padding-bottom: 1rem;
}

.progress-bar-wrapper,
.progress-bar-wrapper * {
  box-sizing: border-box;
}

.step-progress-bar {
  padding: 0;
  position: relative;
  z-index: 1;
}

.progress-step {
  list-style: none;
  position: relative;
  margin-bottom: 1rem;
}

.progress-step .step-index,
.progress-step .step-icon {
  background-color: $gray-200;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: inline-flex;
  transform-origin: center;
  align-items: center;
  justify-content: center;

  &:hover.clickable {
    background-color: shade-color($gray-200, 30%);
  }
}

.step-index,
.step-icon {
  transition: background-color 250ms ease;
  margin-right: 0.5rem;
}

.progress-step .step-label {
  color: var(--text-normal);
  font-weight: 600;
  min-width: 8rem;
  display: inline;
}

.progress-step::before {
  content: "";
  position: absolute;
  z-index: -1;
  background: linear-gradient(to left, #e7eaf3 50%, #4db193 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 200ms ease-in-out;
  left: 1rem;
  width: 3px;
  height: 100%;
  bottom: 50%;
  transform: translateX(-50%);
}

.progress-step.completed::before,
.progress-step.current::before,
.progress-step.has-error::before {
  background-position: left bottom;
}

.progress-step:first-child::before {
  content: none;
}

// warning-light is for partially skipped milestones on portfolios
.text-bg-warning-light {
  background-color: tint-color($warning, 70%);
  color: $body-color;
}

.badge-skipped-light {
  background-color: tint-color($warning, 70%);
}

// Flip the progress bar to horizontal above a given breakpoint
@each $size in map.keys($grid-breakpoints) {
  @include media-breakpoint-up($size) {
    .progress-bar-horizontal-#{$size} {
      .step-progress-bar {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .progress-step {
        margin-bottom: 0;
        text-align: center;
        flex: 1 1 auto;
      }

      .progress-step::before {
        left: inherit;
        bottom: inherit;
        top: 50%;
        height: 3px;
        width: 100%;
        right: 50%;
        transform: translateY(-50%);
      }

      .progress-step .step-label {
        height: 3em;
        position: absolute;
        top: 120%;
        left: 50%;
        transform: translateX(-50%);
        display: block;
      }
    }
  }
}

.progress-step.completed .step-icon {
  background-color: $success;
  border-color: $success;
  box-shadow: none;
  color: #fff;
  animation: spring-down 400ms ease-in-out forwards;

  &:hover.clickable {
    background-color: shade-color($success, 30%);
  }
}

.progress-step.current .step-index {
  background-color: var(--#{$prefix}primary);
  border-color: var(--#{$prefix}primary);
  box-shadow: none;
  color: #fff;
  animation: spring-up 400ms ease forwards;
}

.progress-step.has-error .step-icon {
  // @extend .btn-danger;
  color: white;
  background-color: #bb392d;
  animation: shake 350ms ease-in-out forwards;
}

@keyframes spring-up {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.975);
  }

  40% {
    transform: scale(1.4);
  }

  50%,
  100% {
    transform: scale(1.2);
  }
}

@keyframes spring-down {
  0% {
    transform: scale(1.2);
  }

  35%,
  100% {
    transform: scale(1);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  15% {
    transform: translateX(0.2rem);
  }

  30%,
  60% {
    transform: translateX(-0.4rem);
  }

  45%,
  75% {
    transform: translateX(0.4rem);
  }

  90% {
    transform: translateX(-0.2rem);
  }

  100% {
    transform: translateX(0);
  }
}

html.auth-html {
  height: 100%;
  position: relative;

  body {
    position: relative;
    height: 100%;
    background-color: $gray-100;

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
    }
  }

  .auth-content-wrapper {
    position: relative;
    min-height: 100vh;
    margin: auto;
    padding: 30px;
    border: 1px solid $gray-100;
    background: white;
    border-radius: none;

    @include media-breakpoint-up(sm) {
      width: 100%;
      max-width: 448px;
      min-height: fit-content;
      border-radius: 6px;
    }

    .auth-content-header {
      text-align: center;
    }

    // forms for devise stuff with fancy styling
    .devise-form {
      text-align: center;

      &.is-invalid .form-control {
        @extend .is-invalid;
      }

      .checkbox {
        font-weight: 400;
      }

      .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 1rem;
      }

      .form-control:focus {
        z-index: 2;
        box-shadow: none;
      }

      .fields input,
      .fields select {
        margin-bottom: -1px;
      }

      .fields input:first-child,
      .fields select:first-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      .fields input:last-child,
      .fields select:last-child {
        margin-bottom: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .form-errors {
        margin-top: $spacer * -1;
      }
    }
  }
}

@include portal-theme(allumia) {
  &.auth-html {
    body {
      background-image: url("images/login/background.jpg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

@include portal-theme(duke_rev) {
  &.auth-html {
    .auth-content-wrapper {
      @include media-breakpoint-up(sm) {
        padding: 40px;
        max-width: 500px;
      }
    }
  }
}
